import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class SearchService {
  static async list(searchTerm) {
    const params = {
      searchTerm
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/search`,
      {
        params,
      },
    );

    return response.data;
  }

}
