import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/casestudy/importer/casestudyImporterSelectors';
import CasestudyService from 'src/modules/casestudy/casestudyService';
import fields from 'src/modules/casestudy/importer/casestudyImporterFields';
import { i18n } from 'src/i18n';

const casestudyImporterActions = importerActions(
  'CASESTUDY_IMPORTER',
  selectors,
  CasestudyService.import,
  fields,
  i18n('entities.casestudy.importer.fileName'),
);

export default casestudyImporterActions;