import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/following/importer/followingImporterSelectors';
import FollowingService from 'src/modules/following/followingService';
import fields from 'src/modules/following/importer/followingImporterFields';
import { i18n } from 'src/i18n';

const followingImporterActions = importerActions(
  'FOLLOWING_IMPORTER',
  selectors,
  FollowingService.import,
  fields,
  i18n('entities.following.importer.fileName'),
);

export default followingImporterActions;