import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import actions from 'src/config/theme/store/themeActions';
import getStore from '../store';
import { defaultThemeAlias, defaultThemeMap, defaultThemeSeed } from '../../config/theme/default';

export default class SettingsService {
  static async applyThemeFromTenant() {
    const currentSettings = AuthCurrentTenant.getSettings();

    if (currentSettings) {
      return await this.applyTheme(currentSettings.theme);
    }

    return await this.applyTheme('default');
  }

  static async find() {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/settings`,
    );

    return response.data;
  }

  static async save(settings) {
    const body = {
      settings,
    };

    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.put(
      `/tenant/${tenantId}/settings`,
      body,
    );
    return response.data;
  }

  static async applyTheme(color) {
    const store = await getStore();

    if (!store) {
      return;
    }

    let themeAlias: any = defaultThemeAlias;
    let themeSeed: any = defaultThemeSeed;
    let themeMap: any = defaultThemeMap;
    let themeAliasExtended = {};
    let themeSeedExtended = {};
    let themeMapExtended = {};

    switch (color) {
      case 'cyan':
        const {
          cyanThemeAlias,
          cyanThemeSeed,
          cyanThemeMap
        } = await import('src/config/theme/cyan');
        themeAliasExtended = cyanThemeAlias;
        themeSeedExtended = cyanThemeSeed;
        themeMapExtended = cyanThemeMap;
        break;
      case 'geek-blue':
        const {
          geekBlueThemeAlias,
          geekBlueThemeSeed,
          geekBlueThemeMap
        } = await import('src/config/theme/geek-blue');
        themeAliasExtended = geekBlueThemeAlias;
        themeSeedExtended = geekBlueThemeSeed;
        themeMapExtended = geekBlueThemeMap;
        break;
      case 'gold':
        const {
          goldThemeAlias,
          goldThemeSeed,
          goldThemeMap
        } = await import('src/config/theme/gold');
        themeAliasExtended = goldThemeAlias;
        themeSeedExtended = goldThemeSeed;
        themeMapExtended = goldThemeMap;
        break;
      case 'lime':
        const {
          limeThemeAlias,
          limeThemeSeed,
          limeThemeMap
        } = await import('src/config/theme/lime');
        themeAliasExtended = limeThemeAlias;
        themeSeedExtended = limeThemeSeed;
        themeMapExtended = limeThemeMap;
        break;
      case 'magenta':
        const {
          magentaThemeAlias,
          magentaThemeSeed,
          magentaThemeMap
        } = await import('src/config/theme/magenta');
        themeAliasExtended = magentaThemeAlias;
        themeSeedExtended = magentaThemeSeed;
        themeMapExtended = magentaThemeMap;
        break;
      case 'orange':
        const {
          orangeThemeAlias,
          orangeThemeSeed,
          orangeThemeMap
        } = await import('src/config/theme/orange');
        themeAliasExtended = orangeThemeAlias;
        themeSeedExtended = orangeThemeSeed;
        themeMapExtended = orangeThemeMap;
        break;
      case 'polar-green':
        const {
          polarGreenThemeAlias,
          polarGreenThemeSeed,
          polarGreenThemeMap
        } = await import('src/config/theme/polar-green');
        themeAliasExtended = polarGreenThemeAlias;
        themeSeedExtended = polarGreenThemeSeed;
        themeMapExtended = polarGreenThemeMap;
        break;
      case 'purple':
        const {
          purpleThemeAlias,
          purpleThemeSeed,
          purpleThemeMap
        } = await import('src/config/theme/purple');
        themeAliasExtended = purpleThemeAlias;
        themeSeedExtended = purpleThemeSeed;
        themeMapExtended = purpleThemeMap;
        break;
      case 'red':
        const {
          redThemeAlias,
          redThemeSeed,
          redThemeMap
        } = await import('src/config/theme/red');
        themeAliasExtended = redThemeAlias;
        themeSeedExtended = redThemeSeed;
        themeMapExtended = redThemeMap;
        break;
      case 'volcano':
        const {
          volcanoThemeAlias,
          volcanoThemeSeed,
          volcanoThemeMap
        } = await import('src/config/theme/volcano');
        themeAliasExtended = volcanoThemeAlias;
        themeSeedExtended = volcanoThemeSeed;
        themeMapExtended = volcanoThemeMap;
        break;
      case 'yellow':
        const {
          yellowThemeAlias,
          yellowThemeSeed,
          yellowThemeMap
        } = await import('src/config/theme/yellow');
        themeAliasExtended = yellowThemeAlias;
        themeSeedExtended = yellowThemeSeed;
        themeMapExtended = yellowThemeMap;
        break;
      default:
        break;
    }

    themeAlias = {...themeAlias, ...themeAliasExtended};
    themeSeed = {...themeSeed, ...themeSeedExtended};
    themeMap = {...themeMap, ...themeMapExtended};

    store.dispatch(actions.setTheme(color, themeAlias, themeSeed, themeMap));

    //update css vars used in styles of different components
    const rootStyle: any = document.querySelector(':root');
    rootStyle.style.setProperty('--primary-color', themeSeed.colorPrimary);
    rootStyle.style.setProperty('--link-color', themeAlias.colorLink);
    rootStyle.style.setProperty('--error-color', themeSeed.colorError);
    rootStyle.style.setProperty('--warning-color', themeSeed.colorWarning);
    rootStyle.style.setProperty('--success-color', themeSeed.colorSuccess); }
}
