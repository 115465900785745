import { faAddressBook, faBoxTissue, faBuilding, faClipboardCheck, faEarthEurope, faGlobe, faHandshake, faLightbulb, faMicroscope, faNewspaper, faTags, faTasks, faUserFriends, faVoteYea } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BiSolidReport } from 'react-icons/bi';
import { Gi3DGlasses } from "react-icons/gi";
import { GrConfigure } from 'react-icons/gr';
import { TbReportAnalytics } from 'react-icons/tb';
import config from 'src/config';
import { i18n } from 'src/i18n';
import Permissions from 'src/security/permissions';
import { MdScore } from "react-icons/md";
import { LiaGlassesSolid } from "react-icons/lia";
import { MdAnalytics } from "react-icons/md";

import {
  CreditCardOutlined,
  DashboardOutlined,
  FileSearchOutlined,
  FlagOutlined,
  TableOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { MdInsights, MdManageAccounts } from 'react-icons/md';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <DashboardOutlined />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },
  config.isPlanEnabled && (
    {
      path: '/plan',
      permissionRequired: permissions.planRead,
      icon: <CreditCardOutlined />,
      label: i18n('plan.menu'),
    }),
  {
    path: '/audit-logs',
    icon: <FileSearchOutlined />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/company',
    permissionRequired: permissions.companyRead,
    icon: <FontAwesomeIcon icon={faBuilding} className="anticon anticon-right" />,
    label: i18n('entities.company.menu'),
  },

  {
    path: '/fund',
    permissionRequired: permissions.fundRead,
    icon: <TableOutlined />,
    label: i18n('entities.fund.menu'),
  },

  {
    path: '/engagement',
    permissionRequired: null,
    icon: <MdManageAccounts />,
    label: i18n('menugroups.engagement'),
    children: [
      {
        path: '/initiative',
        permissionRequired: permissions.initiativeRead,
        icon: <FontAwesomeIcon icon={faLightbulb} className="anticon anticon-right" />,
        label: i18n('entities.initiative.menu'),
      },

      {
        path: '/objective',
        permissionRequired: permissions.objectiveRead,
        icon: <FlagOutlined />,
        label: i18n('entities.objective.menu'),
      },

      {
        path: '/interaction',
        permissionRequired: permissions.interactionRead,
        icon: <FontAwesomeIcon icon={faHandshake} className="anticon anticon-right" />,
        label: i18n('entities.interaction.menu'),
      },
      {
        path: '/voting',
        permissionRequired: permissions.votingRead,
        icon: <FontAwesomeIcon icon={faVoteYea} className="anticon anticon-right" />,
        label: i18n('entities.voting.menu'),
      },
    ]
  },


  {
    path: '/task',
    permissionRequired: permissions.taskRead,
    icon: <FontAwesomeIcon icon={faTasks} className="anticon anticon-right" />,
    label: i18n('entities.task.menu'),
  },

  {
    path: '/sources',
    permissionRequired: permissions.researchRead,
    icon: <MdInsights />,
    label: i18n('menugroups.sources'),
    children: [
      {
        path: '/research',
        permissionRequired: permissions.researchRead,
        icon: <FontAwesomeIcon icon={faMicroscope} className="anticon anticon-right" />,
        label: i18n('entities.research.menu'),
      },
      {
        path: '/theme-lens',
        permissionRequired: permissions.benchmarkingRead,
        icon: <Gi3DGlasses />,
        label: i18n('entities.themeLens.menu'),
      },
    ]
  },
  {
    path: '/bench',
    permissionRequired: permissions.benchmarkingRead,
    icon: <FontAwesomeIcon icon={faEarthEurope} className="anticon anticon-right" />,
    label: i18n('entities.benchmarking.menu'),
    children: [
      {
        path: '/benchmarking',
        permissionRequired: permissions.benchmarkingRead,
        icon: <MdAnalytics />,
        label: i18n('entities.benchmarking.analyticsMenu'),
      },
      {
        path: '/benchmark-study',
        permissionRequired: permissions.benchmarkStudyRead,
        icon: <LiaGlassesSolid />,
        label: i18n('entities.benchmarkStudy.menu'),
      },
      {
        path: '/benchmark-score',
        permissionRequired: permissions.benchmarkScoreRead,
        icon: <MdScore />,
        label: i18n('entities.benchmarkScore.menu'),
      },
    ]
  },

  {
    path: '/report',
    permissionRequired: permissions.reportRead,
    icon: <TbReportAnalytics className="anticon anticon-right"/>,
    label: i18n('report.menu'),
  },
  {
    path: '/casestudy',
    permissionRequired: permissions.casestudyRead,
    icon: <BiSolidReport className="anticon anticon-right"/>,
    label: i18n('entities.casestudy.menu'),
  },
  {
    path: '/config',
    exact: true,
    icon: <GrConfigure />,
    label: i18n('menugroups.config'),
    permissionRequired: null,
    children: [
      {
        path: '/user',
        label: i18n('user.menu'),
        permissionRequired: permissions.userEdit,
        icon: <UserAddOutlined />,
      },
          {
        path: '/team',
        permissionRequired: permissions.teamEdit,
        icon: <FontAwesomeIcon icon={faUserFriends} className="anticon anticon-right" />,
        label: i18n('entities.team.menu'),
      },
      {
        path: '/contact',
        permissionRequired: permissions.contactRead,
        icon: <FontAwesomeIcon icon={faAddressBook} className="anticon anticon-right" />,
        label: i18n('entities.contact.menu'),
      },
      {
        path: '/tag',
        permissionRequired: permissions.tagEdit,
        icon: <FontAwesomeIcon icon={faTags} className="anticon anticon-right" />,
        label: i18n('entities.tag.menu'),
      },
      {
        path: '/region',
        permissionRequired: permissions.regionEdit,
        icon: <FontAwesomeIcon icon={faGlobe} className="anticon anticon-right" />,
        label: i18n('entities.region.menu'),
      },
      {
        path: '/outcome',
        permissionRequired: permissions.outcomeEdit,
        icon: <FontAwesomeIcon icon={faClipboardCheck} className="anticon anticon-right" />,
        label: i18n('entities.outcome.menu'),
      },
      {
        path: '/following',
        permissionRequired: permissions.followingEdit,
        icon: <FontAwesomeIcon icon={faNewspaper} className="anticon anticon-right"/>,
        label: i18n('entities.following.menu'),
      },
      {
        path: '/issues',
        permissionRequired: permissions.issuesEdit,
        icon: <FontAwesomeIcon icon={faBoxTissue} className="anticon anticon-right" />,
        label: i18n('entities.issues.menu'),
      },
    ]
  },

].filter(Boolean);
