import list from 'src/modules/benchmarkStudy/list/benchmarkStudyListReducers';
import form from 'src/modules/benchmarkStudy/form/benchmarkStudyFormReducers';
import view from 'src/modules/benchmarkStudy/view/benchmarkStudyViewReducers';
import destroy from 'src/modules/benchmarkStudy/destroy/benchmarkStudyDestroyReducers';
import importerReducer from 'src/modules/benchmarkStudy/importer/benchmarkStudyImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
