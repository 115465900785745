import list from 'src/modules/issues/list/issuesListReducers';
import form from 'src/modules/issues/form/issuesFormReducers';
import view from 'src/modules/issues/view/issuesViewReducers';
import destroy from 'src/modules/issues/destroy/issuesDestroyReducers';
import importerReducer from 'src/modules/issues/importer/issuesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
