import VotingService from 'src/modules/voting/votingService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'VOTING_VIEW';

const votingViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,
  SIGNIFICANT_VOTE_UPDATE_STARTED: `${prefix}_SIGNIFICANT_VOTE_UPDATE_STARTED`,
  SIGNIFICANT_VOTE_UPDATE_SUCCESS: `${prefix}_SIGNIFICANT_VOTE_UPDATE_SUCCESS`,
  SIGNIFICANT_VOTE_UPDATE_ERROR: `${prefix}_SIGNIFICANT_VOTE_UPDATE_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: votingViewActions.FIND_STARTED,
      });

      const record = await VotingService.find(id);

      dispatch({
        type: votingViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: votingViewActions.FIND_ERROR,
      });

      getHistory().push('/voting');
    }
  },

  doSignificantVote: (id, data) => async (dispatch) => {
    try {
      dispatch({
        type: votingViewActions.SIGNIFICANT_VOTE_UPDATE_STARTED,
      });

      const record = await VotingService.updateSignificantVotingItem(
        id,
        data,
      );

      dispatch({
        type: votingViewActions.SIGNIFICANT_VOTE_UPDATE_SUCCESS,
        payload: record[0],
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: votingViewActions.SIGNIFICANT_VOTE_UPDATE_ERROR,
      });
    }

    getHistory().push(`/voting/${id}`);
  }
};

export default votingViewActions;
