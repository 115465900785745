import { UserOutlined } from '@ant-design/icons';
import { Avatar as AntdAvatar } from 'antd';

type Props = {
  avatarUrl?: string,
  size?: any,
  style?: any
  className?: string,
  label?: string,
};

const Avatar = ({ avatarUrl, size, style, className, label }: Props) => (
  <AntdAvatar
    className={`user-dropdown-avatar ${className ? className : ''}`}
    size={size || "large"}
    {...(avatarUrl ? {src: avatarUrl || undefined} : {})}
    alt="avatar"
    {...((label || label !== '') ? {} : {icon: <UserOutlined /> })}
    style={style}
  >{(label || label !== '') ? label : ''}</AntdAvatar>
);

export default Avatar;
