import BenchmarkScoreListActions from 'src/modules/benchmarkScore/list/benchmarkScoreListActions';
import CompanyService from 'src/modules/company/companyService';
import fundListActions from 'src/modules/fund/list/fundListActions';
import InteractionListActions from 'src/modules/interaction/list/interactionListActions';
import ObjectiveListActions from 'src/modules/objective/list/objectiveListActions';
import ResearchListActions from 'src/modules/research/list/researchListActions';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import TaskListActions from 'src/modules/task/list/taskListActions';
import VotingListActions from 'src/modules/voting/list/votingListActions';

const prefix = 'COMPANY_VIEW';

const companyViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id, hasPermissionToReadFunds=true, hasPermissionToReadVoting=true, hasPermissionToReadBenchmarks=true) => async (dispatch) => {
    try {
      dispatch({
        type: companyViewActions.FIND_STARTED,
      });

      const record = await CompanyService.find(id);

      dispatch({
        type: companyViewActions.FIND_SUCCESS,
        payload: record,
      });

      dispatch(InteractionListActions.doFetch({ "company": id }, { 'company': [{ id: id, label: record.companyName }] }));
      dispatch(ObjectiveListActions.doFetch({ "company": id }, { 'company': { id: id, label: record.companyName } }));
      if (hasPermissionToReadFunds) dispatch(fundListActions.doFetchHoldings({ "heldCompany": id }));
      dispatch(ResearchListActions.doFetch({ "companies": [id] }, { 'companies': [{ id: id, label: record.companyName }] }));
      dispatch(TaskListActions.doFetch({ "companyID": id }, { 'companyID': { id: id, label: record.companyName } }));
      if (hasPermissionToReadVoting) dispatch(VotingListActions.doFetch({ "company": id }, { 'company': { id: id, label: record.companyName } }));
      if (hasPermissionToReadBenchmarks) dispatch(BenchmarkScoreListActions.doFetch({ "company": id }, { 'company': { id: id, label: record.companyName } }));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: companyViewActions.FIND_ERROR,
      });

      getHistory().push('/company');
    }
  },
};

export default companyViewActions;
