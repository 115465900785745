import list from 'src/modules/outcome/list/outcomeListReducers';
import form from 'src/modules/outcome/form/outcomeFormReducers';
import view from 'src/modules/outcome/view/outcomeViewReducers';
import destroy from 'src/modules/outcome/destroy/outcomeDestroyReducers';
import importerReducer from 'src/modules/outcome/importer/outcomeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
