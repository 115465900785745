import { Layout, Menu } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import SiderWrapper from 'src/view/layout/styles/SiderWrapper';
import { Link } from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import { useSelector } from 'react-redux';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import layoutActions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus from 'src/view/menus';
import { i18n } from 'src/i18n';
import { useAppDispatch } from 'src/modules/hooks';
import { useNavigate } from 'react-router';

const { Sider } = Layout;

const ToggleMenuDiv = styled.div`
  background-color: white;
  padding: 2px 6px 2px 6px;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  :hover {
    cursor: pointer;
  }
`;

const AppMenu = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );
  const logoUrl = useSelector(authSelectors.selectLogoUrl);

  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  );

  const toggleMenuOnResize = () => {
    window.innerWidth < 576 ? hideMenu() : showMenu();
  };

  useEffect(() => {
    if (menuVisible) toggleMenuOnResize();
    window.addEventListener('resize', toggleMenuOnResize);

    return () => {
      window.removeEventListener(
        'resize',
        toggleMenuOnResize,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedKeys = () => {
    const url = props.url;
    const match = menus.find((option) => {
      if (option.exact) {
        return url === option?.path;
      }

      return (
        url === option.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      return [match.path];
    }
  };

  const hideMenu = () => {
    dispatch(layoutActions.doHideMenu());
  };

  const showMenu = () => {
    dispatch(layoutActions.doShowMenu());
  };

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  const lockedForCurrentPlan = (permission) => {
    return permissionChecker.lockedForCurrentPlan(
      permission,
    );
  };

  const mapToMenu = (menu, kids?) => {
    // check if kids is empty array and if so return null
    if (kids && kids.length === 0) 
      return { label: menu.label, key: menu.path, icon: menu.icon }
    else
      return { label: menu.label, key: menu.path, icon: menu.icon, children: kids };
  }

  // Filter menu items based on user permissions
  const filterMenuPermissions = (menus) => {
    if (!menus) return [];
    return menus
      .filter((menu) => match(menu.permissionRequired))
      .map((menu) => mapToMenu(menu, filterMenuPermissions(menu?.children)));
  };

  // Filter menu items based on user's current plan
  const filterPlan = (menus) => {
    return menus
      .filter((menu) => lockedForCurrentPlan(menu.permissionRequired))
      .map((menu) => mapToMenu(menu));
  };

  // Combine filtered menu items
  const menuItems = [
    ...filterMenuPermissions(menus),
    ...filterPlan(menus),
  ];
  
  const ToggleMenuButton = (props) => (
    <ToggleMenuDiv {...props} onClick={doToggleMenu}>
      {menuVisible ? (
        <MenuFoldOutlined
          className="trigger"
          style={{ fontSize: '107%' }}
        />
      ) : (
        <MenuUnfoldOutlined
          className="trigger"
          style={{ fontSize: '107%' }}
        />
      )}
    </ToggleMenuDiv>
  );

  return (
    <SiderWrapper
      style={{
        transform: menuVisible ? 'translateX(0)' : 'translateX(-14.5em)',
        transition: 'all 300ms ease-in-out',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 999,
      }}
    >
      <Sider theme="light" trigger={null}>
        <div
          className="logo"
          style={{
            backgroundColor: '#F8F8FD',
            padding: '3.5em 0 3.5em 0',
          }}
        >
          {logoUrl ? (
            <Link to="/">
              <img
                src={logoUrl}
                width="164px"
                alt={i18n('app.title')}
              />
            </Link>
          ) : (
            <h2>
              <Link to="/">{i18n('app.title')}</Link>
            </h2>
          )}
        </div>

        <Menu
          theme="light"
          mode="inline"
          selectedKeys={selectedKeys()}
          style={{
            overflowY: 'scroll',
            height: '82vh',
            overflowX: 'hidden',
            direction: 'ltr',
            fontWeight: 'bold',
          }}
          items={menuItems}
          onClick={({ key }) => navigate(`${key}`)}
        >
        </Menu>
      </Sider>
      <ToggleMenuButton style={{
        position: 'fixed',
        top: '24em',
        left: '14em',
        marginLeft: menuVisible ? '-0.5em' : '0.5em',
      }} />
    </SiderWrapper>
  );
};

export default AppMenu;
