import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.task.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.task.fields.title'),
  },
  {
    name: 'description',
    label: i18n('entities.task.fields.description'),
  },
  {
    name: 'targetCompletion',
    label: i18n('entities.task.fields.targetCompletion'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'priority',
    label: i18n('entities.task.fields.priority'),
  },
  {
    name: 'status',
    label: i18n('entities.task.fields.status'),
  },
  {
    name: 'assignedTo',
    label: i18n('entities.task.fields.assignedTo'),
    render: exporterRenders.relationToOne('fullName'),
  },
  {
    name: 'tags',
    label: i18n('entities.task.fields.tags'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'interaction',
    label: i18n('entities.task.fields.interaction'),
    render: exporterRenders.relationToOne('subject'),
  },
  {
    name: 'attachments',
    label: i18n('entities.task.fields.attachments'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.task.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.task.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
