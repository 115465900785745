import actions from 'src/modules/company/form/companyFormActions';

const initialData = {
  initLoading: false,
  saveLoading: false,
  record: null,
  searchRecord: null,
  fundamentalsRecord: null
};

export default (state = initialData, { type, payload }) => {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      record: null,
      searchRecord: null,
      fundamentalsRecord: null,
      initLoading: true,
    };
  }

  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      record: payload,
      initLoading: false,
    };
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      record: null,
      initLoading: false,
    };
  }

  if (type === actions.CREATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.CREATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.CREATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    };
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    };
  }

  if (type === actions.SEARCH_STARTED) {
    return {
      ...state,
      searchRecord: null,
    };
  }

  if (type === actions.SEARCH_SUCCESS) {
    return {
      ...state,
      searchRecord: payload,
    };
  }

  if (type === actions.SEARCH_ERROR) {
    return {
      ...state,
      searchRecord: null,
    };
  }

  if (type === actions.FUNDAMENTALS_STARTED) {
    return {
      ...state,
      fundamentalsRecord: null,
    };
  }

  if (type === actions.FUNDAMENTALS_SUCCESS) {
    return {
      ...state,
      fundamentalsRecord: payload,
    };
  }

  if (type === actions.FUNDAMENTALS_ERROR) {
    return {
      ...state,
      fundamentalsRecord: null,
    };
  }

  return state;
};
