import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.voting.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.voting.fields.title'),
  },
  {
    name: 'company',
    label: i18n('entities.voting.fields.company'),
    render: exporterRenders.relationToOne('companyName'),
  },
  {
    name: 'meetingType',
    label: i18n('entities.voting.fields.meetingType'),
  },
  {
    name: 'votingDeadline',
    label: i18n('entities.voting.fields.votingDeadline'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'dateVoted',
    label: i18n('entities.voting.fields.dateVoted'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'againstManagement',
    label: i18n('entities.voting.fields.againstManagement'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'attachments',
    label: i18n('entities.voting.fields.attachments'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.voting.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.voting.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
