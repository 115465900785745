import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.team.fields.id'),
  },
  {
    name: 'teamName',
    label: i18n('entities.team.fields.teamName'),
  },
  {
    name: 'members',
    label: i18n('entities.team.fields.members'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'contacts',
    label: i18n('entities.team.fields.contacts'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.team.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.team.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
