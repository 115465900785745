import actions from './themeActions';
import { defaultThemeAlias, defaultThemeMap, defaultThemeSeed } from '../default';

const initialData = {
  color: 'default',
  themeAlias: defaultThemeAlias,
  themeSeed: defaultThemeSeed,
  themeMap: defaultThemeMap,
};

export default (state = initialData, { type, payload }) => {

  if (type === actions.SET_THEME) {
    return {
      color: payload.color,
      themeAlias: payload.themeAlias,
      themeSeed: payload.themeSeed,
      themeMap: payload.themeMap,
    };
  }

  return state;
};
