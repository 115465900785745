const votingEnumerators = {
  meetingType: [
    'AGM',
    'EGM',
    'UNKNOWN',
  ],
  significantVoteType: [
    'significant',
    'critical',
  ]
};

export default votingEnumerators;
