import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import dayjs from 'dayjs';

export default [
  {
    name: 'name',
    label: i18n('entities.benchmarkStudy.fields.name'),
    schema: schemas.string(
      i18n('entities.benchmarkStudy.fields.name'),
      {
        "required": true,
        "min": 1,
        "max": 100
      },
    ),
  },
  {
    name: 'methodology',
    label: i18n('entities.benchmarkStudy.fields.methodology'),
    schema: schemas.string(
      i18n('entities.benchmarkStudy.fields.methodology'),
      {},
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.benchmarkStudy.fields.startDate'),
    schema: schemas.date(
      i18n('entities.benchmarkStudy.fields.startDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'endDate',
    label: i18n('entities.benchmarkStudy.fields.endDate'),
    schema: schemas.date(
      i18n('entities.benchmarkStudy.fields.endDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'conductedBy',
    label: i18n('entities.benchmarkStudy.fields.conductedBy'),
    schema: schemas.relationToOne(
      i18n('entities.benchmarkStudy.fields.conductedBy'),
      {},
    ),
  },
];