import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/team/importer/teamImporterSelectors';
import TeamService from 'src/modules/team/teamService';
import fields from 'src/modules/team/importer/teamImporterFields';
import { i18n } from 'src/i18n';

const teamImporterActions = importerActions(
  'TEAM_IMPORTER',
  selectors,
  TeamService.import,
  fields,
  i18n('entities.team.importer.fileName'),
);

export default teamImporterActions;