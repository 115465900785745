import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import objectiveEnumerators from 'src/modules/objective/objectiveEnumerators';
import dayjs from 'dayjs';

export default [
  {
    name: 'company',
    label: i18n('entities.objective.fields.company'),
    schema: schemas.relationToOne(
      i18n('entities.objective.fields.company'),
      {},
    ),
  },
  {
    name: 'objectiveName',
    label: i18n('entities.objective.fields.objectiveName'),
    schema: schemas.string(
      i18n('entities.objective.fields.objectiveName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'targetCompletion',
    label: i18n('entities.objective.fields.targetCompletion'),
    schema: schemas.date(
      i18n('entities.objective.fields.targetCompletion'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'theme',
    label: i18n('entities.objective.fields.theme'),
    schema: schemas.enumerator(
      i18n('entities.objective.fields.theme'),
      {
        "options": objectiveEnumerators.theme
      },
    ),
  },
  {
    name: 'subTheme',
    label: i18n('entities.objective.fields.subTheme'),
    schema: schemas.stringArray(
      i18n('entities.objective.fields.subTheme'),
      {},
    ),
  },
  {
    name: 'stage',
    label: i18n('entities.objective.fields.stage'),
    schema: schemas.enumerator(
      i18n('entities.objective.fields.stage'),
      {
        "options": objectiveEnumerators.stage
      },
    ),
  },
  {
    name: 'initiative',
    label: i18n('entities.objective.fields.initiative'),
    schema: schemas.relationToOne(
      i18n('entities.objective.fields.initiative'),
      {},
    ),
  },
];