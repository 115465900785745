const researchEnumerators = {
  type: [
    'report',
    'investment_note',
    'rating',
  ],
  source: [
    'ethical_screening',
    'msci',
    'sustainalytics',
    'internal'
  ]
};

export default researchEnumerators;
