import list from 'src/modules/initiative/list/initiativeListReducers';
import form from 'src/modules/initiative/form/initiativeFormReducers';
import view from 'src/modules/initiative/view/initiativeViewReducers';
import destroy from 'src/modules/initiative/destroy/initiativeDestroyReducers';
import importerReducer from 'src/modules/initiative/importer/initiativeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
