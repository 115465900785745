import AuthCurrentTenant from "../auth/authCurrentTenant";
import authAxios from "../shared/axios/authAxios";

export default class BenchmarkingService {
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/benchmark-study`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listDemo(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = {
      data: {
        rows: [
          {
            theme: 'PRI Methane',
            industry_summary: {
              industries: [
                { industry: 'Integrated Oil & Gas', score: 4.3, percentage: 33 },
                { industry: 'Oil & Gas Exploration & Production', score: 5.0, percentage: 39 },
                { industry: 'Utilities', score: 1.7, percentage: 13 },
              ],
              overall_average: { score: 3.8, percentage: 29 },
            },
            company_summary: {
              companies: [
                { "company": "Apache", "score": 7, "percentage": 43 },
                { "company": "BHP Billiton", "score": 2, "percentage": 15 },
                { "company": "BP", "score": 9, "percentage": 84 },
                { "company": "CNRL", "score": 4, "percentage": 53 },
                { "company": "Centrica", "score": 11, "percentage": 28 },
                { "company": "Chesapeake", "score": 5, "percentage": 91 },
                { "company": "Chevron", "score": 8, "percentage": 76 },
                { "company": "Sinopec", "score": 1, "percentage": 67 },
                { "company": "CNOOC", "score": 3, "percentage": 45 },
                { "company": "ConocoPhillips", "score": 10, "percentage": 52 },
                { "company": "Con Edison", "score": 0, "percentage": 99 },
                { "company": "Ecopetrol", "score": 6, "percentage": 38 },
                { "company": "ENGIE", "score": 4, "percentage": 79 },
                { "company": "Eni", "score": 7, "percentage": 60 },
                { "company": "ENN Energy", "score": 2, "percentage": 72 },
                { "company": "EOG Resources", "score": 8, "percentage": 23 },
                { "company": "Exxon Mobil", "score": 3, "percentage": 48 },
                { "company": "Inpex", "score": 9, "percentage": 37 },
                { "company": "Kinder Morgan", "score": 11, "percentage": 56 },
                { "company": "Lukoil", "score": 0, "percentage": 92 },
                { "company": "Noble Energy", "score": 5, "percentage": 14 },
                { "company": "Occidental", "score": 6, "percentage": 83 },
                { "company": "Origin", "score": 1, "percentage": 74 },
                { "company": "Pembina Pipeline", "score": 10, "percentage": 65 },
                { "company": "Petrochina", "score": 9, "percentage": 42 },
                { "company": "Petrobras", "score": 7, "percentage": 20 },
                { "company": "Petronas", "score": 8, "percentage": 25 },
                { "company": "PGN", "score": 5, "percentage": 31 },
                { "company": "Repsol", "score": 6, "percentage": 66 },
                { "company": "Royal Dutch Shell", "score": 2, "percentage": 58 },
                { "company": "Sasol", "score": 4, "percentage": 69 },
                { "company": "Saudi Aramco", "score": 11, "percentage": 18 },
                { "company": "SoCalGas", "score": 3, "percentage": 81 },
                { "company": "Statoil", "score": 0, "percentage": 90 },
                { "company": "Tokyo Gas", "score": 9, "percentage": 95 },
                { "company": "Total", "score": 10, "percentage": 27 },
                { "company": "TransCanada", "score": 8, "percentage": 77 },
                { "company": "Ultrapar", "score": 1, "percentage": 70 },
                { "company": "Uniper", "score": 7, "percentage": 63 },
                { "company": "Vermilion Energy", "score": 2, "percentage": 35 },
                { "company": "Woodside Petroleum", "score": 6, "percentage": 40 },
                { "company": "YPF", "score": 5, "percentage": 50 }
              ]

            }
          },
        ],
        count: 1,
      },
    }
    return response.data;
  }

}
