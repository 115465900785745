import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class NotificationService {
  static async list() {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/notification`,
      { },
    );

    return response.data;
  }

  static async patch(id: string, field: string, value: any) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.patch(
      `/tenant/${tenantId}/notification/${id}/${field}/${value}`,
    );

    return response.data;
  };

  static async doRead(id) {
    return this.patch(id, 'opened', true);
  }

  static async doUnread(id) {
    return this.patch(id, 'opened', false);
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/notification`,
      {
        params,
      },
    );

    return response.data;
  }
}
