import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.fund.fields.id'),
  },
  {
    name: 'fundName',
    label: i18n('entities.fund.fields.fundName'),
  },
  {
    name: 'fundObjective',
    label: i18n('entities.fund.fields.fundObjective'),
  },
  {
    name: 'identifier',
    label: i18n('entities.fund.fields.identifier'),
  },
  {
    name: 'country',
    label: i18n('entities.fund.fields.country'),
  },
  {
    name: 'team',
    label: i18n('entities.fund.fields.team'),
    render: exporterRenders.relationToOne('teamName'),
  },
  {
    name: 'externalManager',
    label: i18n('entities.fund.fields.externalManager'),
    render: exporterRenders.relationToOne('companyName'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.fund.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.fund.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
