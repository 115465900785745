import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import tagEnumerators from 'src/modules/tag/tagEnumerators';

export default [
  {
    name: 'label',
    label: i18n('entities.tag.fields.label'),
    schema: schemas.string(
      i18n('entities.tag.fields.label'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'category',
    label: i18n('entities.tag.fields.category'),
    schema: schemas.enumerator(
      i18n('entities.tag.fields.category'),
      {
        "options": tagEnumerators.category
      },
    ),
  },
];