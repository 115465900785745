import list from 'src/modules/voting/list/votingListReducers';
import form from 'src/modules/voting/form/votingFormReducers';
import view from 'src/modules/voting/view/votingViewReducers';
import destroy from 'src/modules/voting/destroy/votingDestroyReducers';
import importerReducer from 'src/modules/voting/importer/votingImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
