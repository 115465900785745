import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class TaskService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/task/${id}`,
      body,
    );

    return response.data;
  }

  static async patch(id, field, value, data) {
    const body = {
      id,
      field,
      value,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.patch(
      `/tenant/${tenantId}/task/${id}/${field}/${value}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/task`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/task`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/task/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/task/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    //MEMO: michael: axios doesn't send empty arrays in GET requests to backend, weird hack to avoid it
    //TODO: michael: change get to post for cases where empty arrays cant be sent or write axios middleware that will handle such case
    if (Array.isArray(filter?.interactions) && filter.interactions.length === 0) {
      return {count: 0, rows: []};
    }

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/task`,
      {
        params,
      },
    );

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/task/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async findAnalyticsByStatus(filter) {
    const tenantId = AuthCurrentTenant.get();

    const params = {
      filter,
    };

    const response = await authAxios.get(
      `/tenant/${tenantId}/task/analytics/byStatus`,
      {params},
    );

    return response.data;
  }
}
