import list from 'src/modules/benchmarkScore/list/benchmarkScoreListReducers';
import form from 'src/modules/benchmarkScore/form/benchmarkScoreFormReducers';
import view from 'src/modules/benchmarkScore/view/benchmarkScoreViewReducers';
import destroy from 'src/modules/benchmarkScore/destroy/benchmarkScoreDestroyReducers';
import importerReducer from 'src/modules/benchmarkScore/importer/benchmarkScoreImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
