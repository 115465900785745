import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import companyEnumerators from 'src/modules/company/companyEnumerators';

export default [
  {
    name: 'companyName',
    label: i18n('entities.company.fields.companyName'),
    schema: schemas.string(
      i18n('entities.company.fields.companyName'),
      {
        "required": true,
        "min": 2,
        "max": 100
      },
    ),
  },
  {
    name: 'ticker',
    label: i18n('entities.company.fields.ticker'),
    schema: schemas.string(
      i18n('entities.company.fields.ticker'),
      {
        "min": 1
      },
    ),
  },
  {
    name: 'address',
    label: i18n('entities.company.fields.address'),
    schema: schemas.string(
      i18n('entities.company.fields.address'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.company.fields.country'),
    schema: schemas.string(
      i18n('entities.company.fields.country'),
      {
        "required": true,
        "min": 2,
        "max": 3
      },
    ),
  },
  {
    name: 'regionCode',
    label: i18n('entities.company.fields.region'),
    schema: schemas.string(
      i18n('entities.company.fields.region'),
      {},
    ),
  },
  {
    name: 'marketCap',
    label: i18n('entities.company.fields.marketCap'),
    schema: schemas.integer(
      i18n('entities.company.fields.marketCap'),
      {},
    ),
  },
  {
    name: 'sector',
    label: i18n('entities.company.fields.sector'),
    schema: schemas.string(
      i18n('entities.company.fields.sector'),
      {},
    ),
  },
  {
    name: 'industry',
    label: i18n('entities.company.fields.industry'),
    schema: schemas.string(
      i18n('entities.company.fields.industry'),
      {},
    ),
  },
  {
    name: 'sic',
    label: i18n('entities.company.fields.sic'),
    schema: schemas.string(
      i18n('entities.company.fields.sic'),
      {
        "min": 4,
        "max": 4
      },
    ),
  },
  {
    name: 'isin',
    label: i18n('entities.company.fields.isin'),
    schema: schemas.string(
      i18n('entities.company.fields.isin'),
      {
        "min": 12,
        "max": 12
      },
    ),
  },
  {
    name: 'sedol',
    label: i18n('entities.company.fields.sedol'),
    schema: schemas.string(
      i18n('entities.company.fields.sedol'),
      {
        "min": 7,
        "max": 7
      },
    ),
  },
  {
    name: 'cusip',
    label: i18n('entities.company.fields.cusip'),
    schema: schemas.string(
      i18n('entities.company.fields.cusip'),
      {
        "min": 9,
        "max": 9
      },
    ),
  },
  {
    name: 'bloomberg',
    label: i18n('entities.company.fields.bloomberg'),
    schema: schemas.string(
      i18n('entities.company.fields.bloomberg'),
      {},
    ),
  },
  {
    name: 'lei',
    label: i18n('entities.company.fields.lei'),
    schema: schemas.string(
      i18n('entities.company.fields.lei'),
      {
        "min": 20,
        "max": 20
      },
    ),
  },
  {
    name: 'logo',
    label: i18n('entities.company.fields.logo'),
    schema: schemas.string(
      i18n('entities.company.fields.logo'),
      {},
    ),
  },
  {
    name: 'classifier',
    label: i18n('entities.company.fields.classifier'),
    schema: schemas.string(
      i18n('entities.company.fields.classifier'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.company.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.company.fields.status'),
      {
        "options": companyEnumerators.status
      },
    ),
  },
  {
    name: 'gicSector',
    label: i18n('entities.company.fields.gicSector'),
    schema: schemas.string(
      i18n('entities.company.fields.gicSector'),
      {},
    ),
  },
  {
    name: 'gicGroup',
    label: i18n('entities.company.fields.gicGroup'),
    schema: schemas.string(
      i18n('entities.company.fields.gicGroup'),
      {},
    ),
  },
  {
    name: 'gicIndustry',
    label: i18n('entities.company.fields.gicIndustry'),
    schema: schemas.string(
      i18n('entities.company.fields.gicIndustry'),
      {},
    ),
  },
  {
    name: 'gicSubIndustry',
    label: i18n('entities.company.fields.gicSubIndustry'),
    schema: schemas.string(
      i18n('entities.company.fields.gicSubIndustry'),
      {},
    ),
  },
];