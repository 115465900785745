import React, { useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import select from 'src/modules/notification/notificationSelectors';
import actions from 'src/modules/notification/list/notificationListActions';
import NotificationItem from './NotificationItem';
import { useAppDispatch } from 'src/modules/hooks';
import { useNavigate } from 'react-router';
import config from 'src/config';
import { i18n } from 'src/i18n';

type Props = {
  isOpen: boolean,
  setOpen: Function,
};

const width = 580; // Don't forget to change this in app.css too!

const MenuButtonContainer = styled.div`
  border-radius: 4px;
  padding-right: 12px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: center;

  :hover {
    background-color: rgba(0,0,0,0.1);
  }
`;

const MenuButton = (props: any) => (
  <FontAwesomeIcon
    style={{
      top: '10px',
      marginLeft: '10px',
      fontSize: '18px',
      display: 'flex',
    }}
    {...props}
  />
);

const NotificationsList = () => {
  const notifications = useSelector(select.selectRows);

  return (
    <div style={{
      paddingTop: '50px',
      overflowY: 'auto',
      position: 'relative',
      height: '100%',
    }}>
      {
        notifications && notifications.length > 0 ?
          _.map(notifications, (notification) => (
            <NotificationItem {...notification} key={notification._id} />
          )) :
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            {i18n('notifications.upToDate')}
          </div>
      }
    </div>
  );
};

const ModalHeader = styled.div`
  z-index: 99999;
  display: flex;
  position: fixed;
  width: ${width - 20}px;
  margin-top: 0;
  margin-right: 40px;
  margin-bottom: 10px;
  padding: 0 40px 6px 0;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: lightblue;
  background-color: #FFFFFF;
`;

const Notifications = (
  {
    isOpen,
    setOpen
  }: Props
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(actions.doFetch());
    const interval = setInterval(() => {
      dispatch(actions.doFetch());
    }, config.notificationPollInterval);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customStyles = {
    overlay: {
      display: 'flex',
      justifyContent: 'flex-end',
      backgroundColor: 'rgba(255, 255, 255, 0)',
    },
    content: {
      zIndex: '9999',
      boxShadow: '-5px 10px 20px -10px rgba(0,0,0,.94)',
      borderStyle: 'none',
      inset: '0 auto auto 0',
      position: 'relative',
      margin: '5em 0px 30px 0',
      padding: '0 20px 20px 20px',
      width: `${width}px`,
      right: 'auto',
      bottom: 'auto',
      overlay: 'hidden auto',
      height: 'fit-content',
      maxHeight: '500px',
    },
  };

  return (
    <Modal
      closeTimeoutMS={600}
      isOpen={isOpen}
      contentLabel={i18n('notifications.title')}
      style={customStyles}
      bodyOpenClassName="modal"
      onRequestClose={() => setOpen(false)}
      ariaHideApp={false}
    >
      <ModalHeader>
        <span style={{ fontSize: '24px', fontStyle: 'bold', marginTop: '10px' }}>
          {i18n('notifications.title')}
        </span>
        <span style={{
          paddingTop: '4px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          width,
        }}>
          <MenuButtonContainer>
            <Dropdown
              overlay={
                <Menu selectedKeys={[]}
                      items={[{label: i18n('notifications.settings'), key: '/settings#notifications'}]}
                      onClick={({ key }) => navigate(`${key}`)} />
              }>
              <MenuButton
                icon={faEllipsis}
              />
            </Dropdown>
          </MenuButtonContainer>
          <MenuButtonContainer onClick={() => setOpen(false)}>
            <MenuButton
              icon={faXmark}
            />
          </MenuButtonContainer>
        </span>
      </ModalHeader>
      <NotificationsList />
    </Modal>
  );
};

export default Notifications;
