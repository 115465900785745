import { createSelector } from 'reselect';

const selectRaw = (state) => state.notifications.list;

const selectLoading = createSelector(
  [selectRaw],
  (raw) => raw.loading,
);

const selectRows = createSelector(
  [selectRaw],
  (raw) => raw.rows,
);

const selectCount = createSelector(
  [selectRaw],
  (raw) => raw.count,
);

const notificationListSelectors = {
  selectLoading,
  selectRows,
  selectCount,
};

export default notificationListSelectors;
