import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.objective.fields.id'),
  },
  {
    name: 'company',
    label: i18n('entities.objective.fields.company'),
    render: exporterRenders.relationToOne('companyName'),
  },
  {
    name: 'objectiveName',
    label: i18n('entities.objective.fields.objectiveName'),
  },
  {
    name: 'targetCompletion',
    label: i18n('entities.objective.fields.targetCompletion'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'theme',
    label: i18n('entities.objective.fields.theme'),
  },
  {
    name: 'subTheme',
    label: i18n('entities.objective.fields.subTheme'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'rationale',
    label: i18n('entities.objective.fields.rationale'),
  },
  {
    name: 'ownedBy',
    label: i18n('entities.objective.fields.ownedBy'),
    render: exporterRenders.relationToOne('fullName'),
  },
  {
    name: 'tags',
    label: i18n('entities.objective.fields.tags'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'attachments',
    label: i18n('entities.objective.fields.attachments'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'stage',
    label: i18n('entities.objective.fields.stage'),
  },
  {
    name: 'stageDate',
    label: i18n('entities.objective.fields.stageDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'initiative',
    label: i18n('entities.objective.fields.initiative'),
    render: exporterRenders.relationToOne('title'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.objective.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.objective.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
