const taskEnumerators = {
  priority: [
    'low',
    'medium',
    'high',
    'urgent',
  ],
  status: [
    'not_started',
    'in_progress',
    'done',
  ],
};

export default taskEnumerators;
