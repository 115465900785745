  const objectiveEnumerators = {
  theme: [
    'Environmental',
    'Social',
    'Governance',
    'Strategy',
  ],
  subTheme: [
    'Composition',
    'Combined Chair/CEO',
    'Diversity',
    'Performance',
    'Tenure',
    'Indpendence',
    'Overboarded',
    'Lead director',
    'Composition',
    'Chair independence',
    'Quantum',
    'Long term incentive plan',
    'Policy',
    'GHG emissions',
    'Air quality',
    'Energy management',
    'Fuel management',
    'Water and wastewater management',
    'Waste and hazardous materials management',
    'Biodiversity impacts',
    'Social Capital',
    'Human rights and community relations',
    'Access and affordability',
    'Customer welfare',
    'Data security and customer privacy',
    'Fair disclosure and labeling',
    'Fair marketing and advertising',
    'Labour relations',
    'Fair labor practices',
    'Employee health, safety and wellbeing',
    'Diversity and inclusion',
    'Compensation and benefits',
    'Recruitment, development and retention',
    'Lifecycle impacts of products and services',
    'Environmental, social impacts on assets & operations',
    'Product packaging',
    'Product quality and safety',
  ],
  stage: [
    'raised',
    'acknowledged',
    'actioned',
    'closed',
  ],
  status: [
    'poorProgress',
    'failed',
    'success',
    'ongoing'
  ],
  priority: [
    'low',
    'medium',
    'high'
  ],
  sdgs: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17'
  ],
};

export default objectiveEnumerators;
