import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import dayjs from 'dayjs';;

export default [
  {
    name: 'title',
    label: i18n('entities.initiative.fields.title'),
    schema: schemas.string(
      i18n('entities.initiative.fields.title'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.initiative.fields.startDate'),
    schema: schemas.date(
      i18n('entities.initiative.fields.startDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'target',
    label: i18n('entities.initiative.fields.target'),
    schema: schemas.date(
      i18n('entities.initiative.fields.target'),
      {},
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'stage',
    label: i18n('entities.initiative.fields.stage'),
    schema: schemas.stringArray(
      i18n('entities.initiative.fields.stage'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.initiative.fields.description'),
    schema: schemas.string(
      i18n('entities.initiative.fields.description'),
      {},
    ),
  },
];