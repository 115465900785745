import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from 'history';
import initializers from 'src/modules/initializers';
import createRootReducer from 'src/modules/reducers';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunkMiddleware from 'redux-thunk';

let store;
let history;

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

export function configureStore(preloadedState?) {
  const middlewares = [
    thunkMiddleware,
    routerMiddleware,
  ].filter(Boolean);

  store = createStore(
    createRootReducer(history, routerReducer),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  for (const initializer of initializers) {
    initializer(store);
  }

  history = createReduxHistory(store);

  return store;
}

export function getHistory() {
  return history;
}

export default function getStore() {
  return store;
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
