import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/voting/importer/votingImporterSelectors';
import VotingService from 'src/modules/voting/votingService';
import fields from 'src/modules/voting/importer/votingImporterFields';
import { i18n } from 'src/i18n';

const votingImporterActions = importerActions(
  'VOTING_IMPORTER',
  selectors,
  VotingService.import,
  fields,
  i18n('entities.voting.importer.fileName'),
);

export default votingImporterActions;