import InteractionService from 'src/modules/interaction/interactionService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';
import taskListActions from 'src/modules/task/list/taskListActions';

const prefix = 'INTERACTION_VIEW';

const interactionViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: interactionViewActions.FIND_STARTED,
      });

      const record = await InteractionService.find(id);

      dispatch({
        type: interactionViewActions.FIND_SUCCESS,
        payload: record,
      });

      dispatch(taskListActions.doFetch({"interaction" :id}));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionViewActions.FIND_ERROR,
      });

      getHistory().push('/interaction');
    }
  },
};

export default interactionViewActions;
