import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import researchEnumerators from 'src/modules/research/researchEnumerators';
import dayjs from 'dayjs';

export default [
  {
    name: 'type',
    label: i18n('entities.research.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.research.fields.type'),
      {
        "required": true,
        "options": researchEnumerators.type
      },
    ),
  },
  {
    name: 'companies',
    label: i18n('entities.research.fields.companies'),
    schema: schemas.relationToMany(
      i18n('entities.research.fields.companies'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'source',
    label: i18n('entities.research.fields.source'),
    schema: schemas.string(
      i18n('entities.research.fields.source'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'researchDate',
    label: i18n('entities.research.fields.researchDate'),
    schema: schemas.date(
      i18n('entities.research.fields.researchDate'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('DD-MMM-YYYY') : value,
  },
  {
    name: 'title',
    label: i18n('entities.research.fields.title'),
    schema: schemas.string(
      i18n('entities.research.fields.title'),
      {},
    ),
  },
  {
    name: 'attachment',
    label: i18n('entities.research.fields.attachment'),
    schema: schemas.files(
      i18n('entities.research.fields.attachment'),
      {
        "required": true
      },
    ),
  },
];
