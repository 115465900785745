import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/initiative/importer/initiativeImporterSelectors';
import InitiativeService from 'src/modules/initiative/initiativeService';
import fields from 'src/modules/initiative/importer/initiativeImporterFields';
import { i18n } from 'src/i18n';

const initiativeImporterActions = importerActions(
  'INITIATIVE_IMPORTER',
  selectors,
  InitiativeService.import,
  fields,
  i18n('entities.initiative.importer.fileName'),
);

export default initiativeImporterActions;