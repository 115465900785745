const prefix = 'THEME';

const themeActions = {
  SET_THEME: `${prefix}_SET_THEME`,

  setTheme: (color, themeAlias, themeSeed, themeMap) => async (dispatch) => {
    dispatch({
      type: themeActions.SET_THEME,
      payload: {color, themeAlias, themeSeed, themeMap}
    });
  },
};

export default themeActions;
