import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/outcome/importer/outcomeImporterSelectors';
import OutcomeService from 'src/modules/outcome/outcomeService';
import fields from 'src/modules/outcome/importer/outcomeImporterFields';
import { i18n } from 'src/i18n';

const outcomeImporterActions = importerActions(
  'OUTCOME_IMPORTER',
  selectors,
  OutcomeService.import,
  fields,
  i18n('entities.outcome.importer.fileName'),
);

export default outcomeImporterActions;