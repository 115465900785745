import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/objective/importer/objectiveImporterSelectors';
import ObjectiveService from 'src/modules/objective/objectiveService';
import fields from 'src/modules/objective/importer/objectiveImporterFields';
import { i18n } from 'src/i18n';

const objectiveImporterActions = importerActions(
  'OBJECTIVE_IMPORTER',
  selectors,
  ObjectiveService.import,
  fields,
  i18n('entities.objective.importer.fileName'),
);

export default objectiveImporterActions;