import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import initiative from 'src/modules/initiative/initiativeReducers';
import company from 'src/modules/company/companyReducers';
import objective from 'src/modules/objective/objectiveReducers';
import interaction from 'src/modules/interaction/interactionReducers';
import task from 'src/modules/task/taskReducers';
import research from 'src/modules/research/researchReducers';
import voting from 'src/modules/voting/votingReducers';
import contact from 'src/modules/contact/contactReducers';
import fund from 'src/modules/fund/fundReducers';
import tag from 'src/modules/tag/tagReducers';
import region from 'src/modules/region/regionReducers';
import team from 'src/modules/team/teamReducers';
import outcome from 'src/modules/outcome/outcomeReducers';
import following from 'src/modules/following/followingReducers';
import issues from 'src/modules/issues/issuesReducers';
import search from 'src/modules/search/searchReducers';
import notifications from './notification/notificationReducers';
import { combineReducers } from 'redux';
import plan from 'src/modules/plan/planReducers';
import report from 'src/modules/report/reportReducers';
import theme from 'src/config/theme/store/themeReducers';
import casestudy from 'src/modules/casestudy/casestudyReducers';
import benchmarking from 'src/modules/benchmarking/benchmarkingReducers';
import benchmarkScore from 'src/modules/benchmarkScore/benchmarkScoreReducers';
import benchmarkStudy from 'src/modules/benchmarkStudy/benchmarkStudyReducers';

export default (history, routerReducer) =>
  combineReducers({
    router: routerReducer,
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    initiative,
    company,
    objective,
    interaction,
    task,
    research,
    voting,
    contact,
    fund,
    tag,
    region,
    team,
    outcome,
    following,
    search,
    issues,
    notifications,
    report,
    theme,
    casestudy,
    benchmarking,
    benchmarkScore,
    benchmarkStudy
  });
