export const defaultThemeAlias = {
  colorLink: '#1890ff', // link color
  colorTextHeading: 'rgba(0, 0, 0, 0.95)', // heading text color
  colorTextDisabled: 'rgba(0, 0, 0, .45)', // disable state color
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', // major shadow for layers
};

export const defaultThemeSeed = {
  colorPrimary: '#1890ff', // primary color for all components
  colorSuccess: '#52c41a', // success state color
  colorWarning: '#faad14', // warning state color
  colorError: '#f5222d', // error state color
  fontSize: 13, // major text font size
  borderRadius: 4, // major border radius
};

export const defaultThemeMap = {
  colorText: 'rgba(0, 0, 0, 0.9)', // major text color
  colorTextSecondary: 'rgba(0, 0, 0, .75)', // secondary text color
  colorBorder: '#d9d9d9', // major border color
};