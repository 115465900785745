import { i18n } from 'src/i18n';

class Roles {
  static get values() {
    return {
      user: 'user',
      admin: 'admin',
      globaladmin: 'globaladmin',
      tenantadmin: 'tenantadmin',
      readonly: 'readonly', 
      voting: 'voting',
      casestudies: 'casestudies',
      benchmarking: 'benchmarking',
      funds: 'funds',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }
}

export default Roles;
