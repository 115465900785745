import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class StockdataService {

  static async search(searchTerm) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/stockdata/search/${searchTerm}`,
    );

    return response.data;
  }

  static async fundamentals(identifier) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/stockdata/fundamentals/${identifier}`,
    );

    return response.data;
  }
}
