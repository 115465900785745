import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.research.fields.id'),
  },
  {
    name: 'type',
    label: i18n('entities.research.fields.type'),
  },
  {
    name: 'companies',
    label: i18n('entities.research.fields.companies'),
    render: exporterRenders.relationToMany('companyName', true),
  },
  {
    name: 'source',
    label: i18n('entities.research.fields.source'),
  },
  {
    name: 'researchDate',
    label: i18n('entities.research.fields.researchDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'title',
    label: i18n('entities.research.fields.title'),
  },
  {
    name: 'attachment',
    label: i18n('entities.research.fields.attachment'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.research.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.research.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
