import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.contact.fields.name'),
    schema: schemas.string(
      i18n('entities.contact.fields.name'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'company',
    label: i18n('entities.contact.fields.company'),
    schema: schemas.relationToOne(
      i18n('entities.contact.fields.company'),
      {
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.contact.fields.role'),
    schema: schemas.string(
      i18n('entities.contact.fields.role'),
      {
      },
    ),
  },
  {
    name: 'email',
    label: i18n('entities.contact.fields.email'),
    schema: schemas.string(
      i18n('entities.contact.fields.email'),
      {
        "min": 4
      },
    ),
  },
  {
    name: 'phoneNumber',
    label: i18n('entities.contact.fields.phoneNumber'),
    schema: schemas.string(
      i18n('entities.contact.fields.phoneNumber'),
      {},
    ),
  },
  {
    name: 'alternateNumber',
    label: i18n('entities.contact.fields.alternateNumber'),
    schema: schemas.string(
      i18n('entities.contact.fields.alternateNumber'),
      {},
    ),
  },
];