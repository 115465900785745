import actions from './notificationListActions';
import _ from 'lodash';

const INITIAL_PAGE_SIZE = 10;

const initialData = {
  rows: [],
  count: 1,
  loading: false,
  filter: {},
  rawFilter: {},
  pagination: {
    current: 1,
    pageSize: 10,
  },
  sorter: {},
  selectedKeys: [],
};

export default (state = initialData, { type, payload }) => {
  const { rows } = state;
  if (type === actions.NOTIFICATION_READ) {
    return {
      ...state,
      rows: _.map(rows, (notification: any) => ({
        ...notification,
        opened: notification._id === payload._id ?
          true :
          notification.opened,
      }))
    };
  }

  if (type === actions.NOTIFICATION_UNREAD) {
    return {
      ...state,
      rows: _.map(rows, (notification: any) => ({
        ...notification,
        opened: notification._id === payload._id ?
          false :
          notification.opened,
      }))
    };
  }

  if (type === actions.NOTIFICATION_DELETE) {
    return {
      ...state,
      rows: _.filter(rows, (notification: any) => (notification._id !== payload._id ))
    };
  }

  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      selectedKeys: [],
      filter: payload ? payload.filter : {},
      rawFilter: payload ? payload.rawFilter : {},
      pagination:
        payload && payload.keepPagination
          ? state.pagination
          : {
              current: 1,
              pageSize: INITIAL_PAGE_SIZE,
            },
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    };
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    };
  }

  return state;
};
