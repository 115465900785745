import Importer from 'src/modules/shared/importer/importer';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import chunk from 'lodash/chunk';
import md5 from 'md5';

async function importRow(
  dispatch,
  actions,
  importer,
  importFn,
  row,
) {
  try {
    const importableRow = await importer.castForImport(row);
    const importHash = md5(JSON.stringify(importableRow));
    await importFn(importableRow, importHash);

    dispatch({
      type: actions.IMPORT_BATCH_SUCCESS,
      payload: {
        line: row._line,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.IMPORT_BATCH_ERROR,
      payload: {
        line: row._line,
        errorMessage: Errors.selectMessage(error),
      },
    });
  }
}

async function importRows(
  dispatch,
  actions,
  importer,
  importFn,
  rows:any[],
) {
  try {
    const importableRows = await Promise.all(rows.map(async(row, index) => await importer.castForImport(row)));
    const importHash = md5(JSON.stringify(importableRows));
    await importFn(importableRows, importHash);

    rows.map(async(row, index) => {
      dispatch({
        type: actions.IMPORT_BATCH_SUCCESS,
        payload: {
          line: row._line,
        },
      })
    });
  } catch (error) {
    rows.map(async(row, index) => {
      dispatch({
      type: actions.IMPORT_BATCH_ERROR,
      payload: {
        line: row._line,
        errorMessage: Errors.selectMessage(error),
      },
    })
  });
}
}

export default (
  prefix,
  selectors,
  importFn,
  importFields,
  templateFileName,
  batchSize = 10,
  mode = 'row', // could also be batches or file
  values: any[] = [],
) => {
  const actions = {
    RESETED: `${prefix}_RESETED`,

    FILE_READ_ERROR: `${prefix}_FILE_READ_ERROR`,
    FILE_READ_SUCCESS: `${prefix}_FILE_READ_SUCCESS`,

    IMPORT_STARTED: `${prefix}_IMPORT_STARTED`,
    IMPORT_ERROR: `${prefix}_IMPORT_ERROR`,
    IMPORT_PAUSED: `${prefix}_IMPORT_PAUSED`,
    IMPORT_SUCCESS: `${prefix}_IMPORT_SUCCESS`,

    IMPORT_BATCH_ERROR: `${prefix}_IMPORT_BATCH_ERROR`,
    IMPORT_BATCH_SUCCESS: `${prefix}_IMPORT_BATCH_SUCCESS`,

    doReset: () => {
      return {
        type: actions.RESETED,
      };
    },

    doPause: () => {
      return {
        type: actions.IMPORT_PAUSED,
      };
    },

    doImport: () => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.IMPORT_STARTED,
        });
       
        const pendingRows = selectors.selectPendingRows(
          getState(),
        );

        const importer = new Importer(importFields);

        if ('file' === mode) {
          await importRows(dispatch,
                        actions,
                        importer,
                        importFn,
                        pendingRows);
        } else {
          const pendingBatches = chunk(
            pendingRows,
            batchSize,
          );
  
          for (let batch of pendingBatches) {
            const paused = !selectors.selectImporting(
              getState(),
            );
  
            if (paused) {
              return;
            }
  
            await Promise.all(
              batch.map((row, index) =>
                setTimeout(() => {importRow(
                            dispatch,
                            actions,
                            importer,
                            importFn,
                            row)}, (index + 1) * 500)
              ),
            );
  
          }  
        }

        dispatch({
          type: actions.IMPORT_SUCCESS,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.IMPORT_ERROR,
        });
      }
    },

    doDownloadTemplate: () => async (dispatch) => {
      const importer = new Importer(importFields, values as never[]);
      importer.downloadTemplate(templateFileName);
    },

    doReadFile: (file) => async (dispatch) => {
      try {
        const importer = new Importer(importFields);

        let rawData = await importer.convertExcelFileToJson(
          file,
        );

        if (!rawData || !rawData.length) {
          throw new Error(
            i18n('importer.errors.invalidFileEmpty'),
          );
        }

        rawData = await Promise.all(
          rawData.map(async (row, index) => {
            return await importer.castForDisplay(
              row,
              index,
            );
          }),
        );

        dispatch({
          type: actions.FILE_READ_SUCCESS,
          payload: rawData,
        });

      } catch (error) {
        console.error(error);
        dispatch({
          type: actions.FILE_READ_ERROR,
          payload: error,
        });
      }
    },
    
  };

  return actions;
};
