import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.interaction.fields.id'),
  },
  {
    name: 'company',
    label: i18n('entities.interaction.fields.company'),
    render: exporterRenders.relationToOne('companyName'),
  },
  {
    name: 'interactionDate',
    label: i18n('entities.interaction.fields.interactionDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'internalAttendees',
    label: i18n('entities.interaction.fields.internalAttendees'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'externalAttendees',
    label: i18n('entities.interaction.fields.externalAttendees'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'meetingType',
    label: i18n('entities.interaction.fields.meetingType'),
  },
  {
    name: 'subject',
    label: i18n('entities.interaction.fields.subject'),
  },
  {
    name: 'purpose',
    label: i18n('entities.interaction.fields.purpose'),
  },
  {
    name: 'meetingSummary',
    label: i18n('entities.interaction.fields.meetingSummary'),
  },
  {
    name: 'attachments',
    label: i18n('entities.interaction.fields.attachments'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'feeling',
    label: i18n('entities.interaction.fields.feeling'),
  },
  {
    name: 'investmentAction',
    label: i18n('entities.interaction.fields.investmentAction'),
  },
  {
    name: 'stewardshipEscalation',
    label: i18n('entities.interaction.fields.stewardshipEscalation'),
    render: value => (
      typeof value === 'boolean' && value ? 'yes' : 'no'
    ),
  },
  {
    name: 'objectiveLink',
    label: i18n('entities.interaction.fields.objectiveLink'),
    render: exporterRenders.relationToOne('objectiveName'),
  },
  {
    name: 'outcome',
    label: i18n('entities.interaction.fields.outcome'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'tags',
    label: i18n('entities.interaction.fields.tags'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'tasks',
    label: i18n('entities.interaction.fields.tasks'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.interaction.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.interaction.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
