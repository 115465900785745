import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'teamName',
    label: i18n('entities.team.fields.teamName'),
    schema: schemas.string(
      i18n('entities.team.fields.teamName'),
      {},
    ),
  },
  {
    name: 'members',
    label: i18n('entities.team.fields.members'),
    schema: schemas.relationToMany(
      i18n('entities.team.fields.members'),
      {},
    ),
  },
  {
    name: 'contacts',
    label: i18n('entities.team.fields.contacts'),
    schema: schemas.relationToMany(
      i18n('entities.team.fields.contacts'),
      {},
    ),
  },
];