import Header from 'src/view/layout/Header';
import Menu from 'src/view/layout/Menu';
import LayoutWrapper from 'src/view/layout/styles/LayoutWrapper';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import layoutSelectors from 'src/modules/layout/layoutSelectors';

const Layout = (props) => {
  const location = useLocation();

  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );

  return (
    <LayoutWrapper>
      <Menu url={location.pathname} />

      <Header />

      <div style={{
        marginTop: '6em',
        marginLeft: '2em',
        width: menuVisible ? 'calc(100% - 20em)' : 'calc(100% - 5.5em)',
        transform: menuVisible ? 'translateX(14.5em)' : 'translateX(0)',
        transition: 'all 300ms ease-in-out',
        zIndex: '0',
      }}>
        {props.children}
      </div>
    </LayoutWrapper>
  );
};

export default Layout;
