import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/benchmarkScore/importer/benchmarkScoreImporterSelectors';
import BenchmarkScoreService from 'src/modules/benchmarkScore/benchmarkScoreService';
import fields from 'src/modules/benchmarkScore/importer/benchmarkScoreImporterFields';
import { i18n } from 'src/i18n';

const benchmarkScoreImporterActions = importerActions(
  'BENCHMARKSCORE_IMPORTER',
  selectors,
  BenchmarkScoreService.import,
  fields,
  i18n('entities.benchmarkScore.importer.fileName'),
  10,
  'file'
);

export default benchmarkScoreImporterActions;