/**
 * List of Plans
 */
class Plans {
  static get values() {
    return {
      free: 'free',
      standard: 'standard',
      professional: 'professional',
      enterprise: 'enterprise',
    };
  }
}

export default Plans;
