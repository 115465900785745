const bgDarker = '#C6C8CB'
const bgDark = '#E6E8EB'
const bgMedium = '#F4F6F9';
const bgMediumLight = '#F9F9F9';
const bgLight = '#FFFFFF';

const textDark = '#000000';
const textMedium = '#01002C';
const textMediumLight = '#333324';
const textLight = '#404040';

const dangerRed = '#E75185';
const createGreen = '#5CC7B0';
const createGreenDark = '#2CA5B0';
const warning = '#FFCC00';
const blue = '#3025A5';
const lightBlue = '#1890FF';

const borderLight = '#DFE1E7';

export {
  bgDark,
  bgDarker,
  bgMedium,
  bgMediumLight,
  bgLight,
  textDark,
  textMedium,
  textMediumLight,
  textLight,
  dangerRed,
  createGreen,
  createGreenDark,
  warning,
  blue,
  lightBlue,
  borderLight,
}
