import dayjs from 'dayjs';;

const exporterRenders = {
  stringArray: () => (value) => (value || []).join(', '),
  json: () => (value) =>
    value ? JSON.stringify(value, null, 2) : null,
  decimal: (fractionDigits?) => (value) =>
    value
      ? fractionDigits
        ? Number(value).toFixed(fractionDigits)
        : Number(value)
      : null,
  boolean: () => (value) => String(Boolean(value)),
  relationToOne: (field?) => (value) =>{
    if (field)
      return (value && value[field]) || null 
    else
      (value && value.id) || null
  },
  relationToMany: (field?, commaSeparated?) => (value) =>
    (value || []).map((item) => field ? item[field] : item.id).join(`${commaSeparated ? ',' : ''} `),
  filesOrImages: () => (value) =>
    (value || []).map((item) => item.downloadUrl).join(' '),
  datetime: () => (value) =>
    value ? dayjs(value).format('DD-MMM-YYYY HH:mm') : null,
};

export default exporterRenders;
