import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'title',
    label: i18n('entities.casestudy.fields.title'),
    schema: schemas.string(
      i18n('entities.casestudy.fields.title'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'summary',
    label: i18n('entities.casestudy.fields.summary'),
    schema: schemas.string(
      i18n('entities.casestudy.fields.summary'),
      {
        "required": true,
        "min": 5
      },
    ),
  },
  {
    name: 'issue',
    label: i18n('entities.casestudy.fields.issue'),
    schema: schemas.string(
      i18n('entities.casestudy.fields.issue'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'action',
    label: i18n('entities.casestudy.fields.action'),
    schema: schemas.string(
      i18n('entities.casestudy.fields.action'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'lessons',
    label: i18n('entities.casestudy.fields.lessons'),
    schema: schemas.string(
      i18n('entities.casestudy.fields.lessons'),
      {},
    ),
  },
  {
    name: 'sdgs',
    label: i18n('entities.casestudy.fields.sdgs'),
    schema: schemas.stringArray(
      i18n('entities.casestudy.fields.sdgs'),
      {},
    ),
  },
];