import list from 'src/modules/casestudy/list/casestudyListReducers';
import form from 'src/modules/casestudy/form/casestudyFormReducers';
import view from 'src/modules/casestudy/view/casestudyViewReducers';
import destroy from 'src/modules/casestudy/destroy/casestudyDestroyReducers';
import importerReducer from 'src/modules/casestudy/importer/casestudyImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
