import { Layout as AntLayout } from 'antd';
import styled from 'styled-components';
import { bgMedium } from 'src/view/shared/Colours';

const LayoutWrapper = styled(AntLayout)`
  background-color: ${bgMedium};

  .ant-layout {
    min-height: 100vh;
    background-color: ${bgMedium};
  }

  .ant-layout-header {
    height: 6em;
    padding: 2em 1em 1.5em 1em;
    line-height: inherit;
  }

  .ant-layout-content {
    background-color: ${bgMedium};
    margin: 24px;
  }
`;

export default LayoutWrapper;
