import list from 'src/modules/team/list/teamListReducers';
import form from 'src/modules/team/form/teamFormReducers';
import view from 'src/modules/team/view/teamViewReducers';
import destroy from 'src/modules/team/destroy/teamDestroyReducers';
import importerReducer from 'src/modules/team/importer/teamImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
