import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.casestudy.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.casestudy.fields.title'),
  },
  {
    name: 'summary',
    label: i18n('entities.casestudy.fields.summary'),
  },
  {
    name: 'issue',
    label: i18n('entities.casestudy.fields.issue'),
  },
  {
    name: 'action',
    label: i18n('entities.casestudy.fields.action'),
  },
  {
    name: 'lessons',
    label: i18n('entities.casestudy.fields.lessons'),
  },
  {
    name: 'sdgs',
    label: i18n('entities.casestudy.fields.sdgs'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.casestudy.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.casestudy.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
