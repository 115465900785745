const companyEnumerators = {
  status: [
    'approved',
    'pending',
    'rejected',
    'obsolete',
  ],
  entityType: [
    'listed',
    'unlisted',
    'investor',
    'body',
    'ngo',
    'serviceProvider',
  ],
};

export default companyEnumerators;
