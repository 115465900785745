import {
  getHistory,
} from 'src/modules/store';
import React from 'react';
import { Provider } from 'react-redux';
import RoutesComponent from 'src/view/shared/routes/RoutesComponent';
import { ConfigProvider, theme } from 'antd';
import { getAntdLanguage } from 'src/i18n';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import 'src/app.css';
import { HistoryListener } from './modules/HistoryListener';
// import LogRocket from 'logrocket';
// LogRocket.init('1ate0u/impactivetest');

const App = (props) => {
  const { defaultAlgorithm } = theme;
  const { themeAlias, themeSeed, themeMap } = props.store.getState().theme;

  const customAlgorithm = seed => {
    return { ...defaultAlgorithm({ ...seed, ...themeSeed }), ...themeMap };
  };

  return (
    <ConfigProvider locale={getAntdLanguage()}
      theme={{
        token: {
          fontFamily: 'Quicksand',
          ...themeAlias
        },
        algorithm: [customAlgorithm],
      }}
    >
      <Provider store={props.store}>
        <Router history={getHistory()}>
          <HistoryListener>
            <RoutesComponent />
          </HistoryListener>
        </Router>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
