/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {

      objectiveAttachments: {
        id: 'objectiveAttachments',
        folder: 'tenant/:tenantId/objective/attachments',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      interactionAttachments: {
        id: 'interactionAttachments',
        folder: 'tenant/:tenantId/interaction/attachments',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      taskAttachments: {
        id: 'taskAttachments',
        folder: 'tenant/:tenantId/task/attachments',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      researchAttachment: {
        id: 'researchAttachment',
        folder: 'tenant/:tenantId/research/attachment',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      votingAttachments: {
        id: 'votingAttachments',
        folder: 'tenant/:tenantId/voting/attachments',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      initiativeAttachments: {
        id: 'initiativeAttachments',
        folder: 'tenant/:tenantId/initiative/attachments',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
    };
  }
}
