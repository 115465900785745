import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.following.fields.id'),
  },
  {
    name: 'entity',
    label: i18n('entities.following.fields.entity'),
  },
  {
    name: 'identity',
    label: i18n('entities.following.fields.identity'),
  },
  {
    name: 'filter',
    label: i18n('entities.following.fields.filter'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.following.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.following.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
