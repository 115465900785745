import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/interaction/importer/interactionImporterSelectors';
import InteractionService from 'src/modules/interaction/interactionService';
import fields from 'src/modules/interaction/importer/interactionImporterFields';
import { i18n } from 'src/i18n';

const interactionImporterActions = importerActions(
  'INTERACTION_IMPORTER',
  selectors,
  InteractionService.import,
  fields,
  i18n('entities.interaction.importer.fileName'),
);

export default interactionImporterActions;