import InteractionService from 'src/modules/interaction/interactionService';
import Message from 'src/view/shared/message';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';

const prefix = 'INTERACTION_MEETING_NOTES';

const interactionMeetingNotesActions = {
  ADD_NOTE_STARTED: `${prefix}_ADD_NOTE_STARTED`,
  ADD_NOTE_SUCCESS: `${prefix}_ADD_NOTE_SUCCESS`,
  ADD_NOTE_ERROR: `${prefix}_ADD_NOTE_ERROR`,

  UPDATE_NOTE_STARTED: `${prefix}_UPDATE_NOTE_STARTED`,
  UPDATE_NOTE_SUCCESS: `${prefix}_UPDATE_NOTE_SUCCESS`,
  UPDATE_NOTE_ERROR: `${prefix}_UPDATE_NOTE_ERROR`,

  DELETE_NOTE_STARTED: `${prefix}_DELETE_NOTE_STARTED`,
  DELETE_NOTE_SUCCESS: `${prefix}_DELETE_NOTE_SUCCESS`,
  DELETE_NOTE_ERROR: `${prefix}_DELETE_NOTE_ERROR`,

  doAddNote: (id, values, callback) => async (dispatch, getState) => {
    try {
      dispatch({
        type: interactionMeetingNotesActions.ADD_NOTE_STARTED,
      });

      const response = await InteractionService.addNote(id, values, callback);

      dispatch({
        type: interactionMeetingNotesActions.ADD_NOTE_SUCCESS,
        payload: response
      });

      Message.success(
        i18n('entities.interaction.update.success'),
      );

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionMeetingNotesActions.ADD_NOTE_ERROR,
      });
    }
  },

  doUpdateNote: (id, noteId, values, callback) => async (dispatch, getState) => {
    try {
      dispatch({
        type: interactionMeetingNotesActions.UPDATE_NOTE_STARTED,
      });

      const response = await InteractionService.updateNote(id, noteId, values, callback);

      dispatch({
        type: interactionMeetingNotesActions.UPDATE_NOTE_SUCCESS,
        payload: response
      });

      Message.success(
        i18n('entities.interaction.update.success'),
      );

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionMeetingNotesActions.UPDATE_NOTE_ERROR,
      });
    }
  },

  doDeleteNote: (id, noteId, callback) => async (dispatch, getState) => {
    try {
      dispatch({
        type: interactionMeetingNotesActions.DELETE_NOTE_STARTED,
      });

      const response = await InteractionService.deleteNote(id, noteId, callback);

      dispatch({
        type: interactionMeetingNotesActions.DELETE_NOTE_SUCCESS,
        payload: response
      });

      Message.success(
        i18n('entities.interaction.update.success'),
      );

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionMeetingNotesActions.DELETE_NOTE_ERROR,
      });
    }
  },
};

export default interactionMeetingNotesActions;
