import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/benchmarkStudy/importer/benchmarkStudyImporterSelectors';
import BenchmarkStudyService from 'src/modules/benchmarkStudy/benchmarkStudyService';
import fields from 'src/modules/benchmarkStudy/importer/benchmarkStudyImporterFields';
import { i18n } from 'src/i18n';

const benchmarkStudyImporterActions = importerActions(
  'BENCHMARKSTUDY_IMPORTER',
  selectors,
  BenchmarkStudyService.import,
  fields,
  i18n('entities.benchmarkStudy.importer.fileName'),
);

export default benchmarkStudyImporterActions;