import list from 'src/modules/objective/list/objectiveListReducers';
import form from 'src/modules/objective/form/objectiveFormReducers';
import view from 'src/modules/objective/view/objectiveViewReducers';
import destroy from 'src/modules/objective/destroy/objectiveDestroyReducers';
import importerReducer from 'src/modules/objective/importer/objectiveImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
