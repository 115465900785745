import list from 'src/modules/following/list/followingListReducers';
import form from 'src/modules/following/form/followingFormReducers';
import view from 'src/modules/following/view/followingViewReducers';
import destroy from 'src/modules/following/destroy/followingDestroyReducers';
import importerReducer from 'src/modules/following/importer/followingImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
