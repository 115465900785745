import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { useSelector } from 'react-redux';
import { bgDark, dangerRed } from 'src/view/shared/Colours';
import select from 'src/modules/notification/notificationSelectors';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { i18n } from 'src/i18n';

type Props = {
  setModalOpen: Function,
};

const Wrapper = styled.div`
  background-color: ${bgDark};
  position: relative;
  margin: 0px;
  top: 1px;
  font-size: 20px;
  border-radius: 50%;
  padding: 5px 12px 4px 12px;

  &:hover {
    opacity: 0.25;
    cursor: pointer;
  }
`;

const Counter = styled.span`
  position: absolute;
  right: -4px;
  top: -1px;
  line-height: 12px;
  background-color: ${dangerRed};
  padding: 2px 4px;
  border-radius: 12px;
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 400;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.2);
`;

const Bell = ({ setModalOpen }: Props) => {
  const notifications = useSelector(select.selectRows);
  const count = _.filter(
    notifications,
    (notification) => !notification.opened
  ).length;

  return (
    <div style={{ marginLeft: '16px', position: 'relative', top: '-4px' }}>
      <Wrapper onClick={() => setModalOpen(true)}>
        <Tooltip title={i18n('notifications.title')} placement="bottom">
          <FontAwesomeIcon
            icon={faBell}
          />
        </Tooltip>
        {count > 0 && <Counter> {count}</Counter>}
      </Wrapper>
    </div>
  );
}

export default Bell;
