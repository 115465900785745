import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/issues/importer/issuesImporterSelectors';
import IssuesService from 'src/modules/issues/issuesService';
import fields from 'src/modules/issues/importer/issuesImporterFields';
import { i18n } from 'src/i18n';

const issuesImporterActions = importerActions(
  'ISSUES_IMPORTER',
  selectors,
  IssuesService.import,
  fields,
  i18n('entities.issues.importer.fileName'),
);

export default issuesImporterActions;