import interactionListActions from 'src/modules/interaction/list/interactionListActions';
import ObjectiveService from 'src/modules/objective/objectiveService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'OBJECTIVE_VIEW';

const objectiveViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: objectiveViewActions.FIND_STARTED,
      });

      const record = await ObjectiveService.find(id);

      dispatch({
        type: objectiveViewActions.FIND_SUCCESS,
        payload: record,
      });

      dispatch(interactionListActions.doFetch({"objectiveLink" :id}));

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: objectiveViewActions.FIND_ERROR,
      });

      getHistory().push('/objective');
    }
  },
};

export default objectiveViewActions;
