import styled from 'styled-components';
import { AutoComplete, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import CompanyService from 'src/modules/company/companyService';
import ObjectiveService from 'src/modules/objective/objectiveService';
import InitiativeService from 'src/modules/initiative/initiativeService';
import InteractionService from 'src/modules/interaction/interactionService';
import { i18n } from 'src/i18n';
import actions from 'src/modules/search/list/searchListActions';
import { Link } from 'react-router-dom';
import FundService from 'src/modules/fund/fundService';
import ResearchService from 'src/modules/research/researchService';
import TaskService from 'src/modules/task/taskService';
import { useAppDispatch } from 'src/modules/hooks';

const StyledAutoComplete = styled(AutoComplete)`
  .ant-input {
    position: relative;
    top: -0.5em;
    border-color: #FFFFFF;
    border-radius: 1.5em;
    padding: 0.75em 1em 0.75em 1em;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.10), 0 6px 40px 0 rgba(0, 0, 0, 0.07);
  }
  
  .ant-input-group-addon {
    background: rgba(0,0,0,0);
  }

  .ant-input-search-button {
    border-radius: 3em !important;
    height: 3em !important;
    width: 3em !important;
    position: relative;
    top: -0.70em;
    right: 0.75em;
    z-index: 999;
    font-size: 18px;
    box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.10), 0 6px 40px 0 rgba(0, 0, 0, 0.07);
  }
`;


const GlobalSearch = (props) => {
  const dispatch = useAppDispatch();

  const renderTitle = (title: string, path: string) => (
    <span>
      {title}
      <Link style={{ float: 'right' }} to={`/${path}`}>
         {i18n('common.showAll')}
      </Link>
    </span>
  );

  const fetchFn = async(value, limit) => {
    let results = {
      companyData: await CompanyService.listAutocomplete(value, limit),
      objectiveData: await ObjectiveService.listAutocomplete(value, limit),
      initiativeData: await InitiativeService.listAutocomplete(value, limit),
      interactionData: await InteractionService.listAutocomplete(value, limit),
      fundData: await FundService.listAutocomplete(value, limit),
      researchData: await ResearchService.listAutocomplete(value, limit),
      taskData: await TaskService.listAutocomplete(value, limit)
    }
    return results;
  };

  const [
    inMemoryDataSource,
    setInMemoryDataSource,
  ] = useState<Array<{label: JSX.Element, resourceUri: string, options: any}>>([]);

  const [fullDataSource, setFullDataSource] = useState<Array<{label: JSX.Element, resourceUri: string, options: any}>>([]);

  useEffect(() => {
    handleSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataSource = inMemoryDataSource;

  const handleSearch = async (value) => {
    if (value?.length > 0)
      return handleInMemorySearch(value);
    else
      return;
  };

  const _filteredDatasource = (datasourceToFilter, filterValue) => {
    let _groupOptions = datasourceToFilter.map((optionGroup) => {
      return { label: optionGroup.label, resourceUri: optionGroup.resourceUri, options:
          optionGroup['options'].filter((item) =>
          String(item.label || '')
          .toLowerCase()
          .includes(String(filterValue || '').toLowerCase()))}
    });
    return _groupOptions;
  }

  const handleInMemorySearch = async (value) => {
    let _fullDataSource = fullDataSource;

    if (!_fullDataSource || !_fullDataSource.length) {
      _fullDataSource = await fetchAllResults();
    }

    const filteredDatasource = _filteredDatasource(_fullDataSource, value);

    setInMemoryDataSource(filteredDatasource);
  };

  const processResults = (results) => {
    let optionsToReturn = [
      {
        label: renderTitle(i18n('entities.company.label'), 'company'),
        resourceUri: 'company',
        options: results.companyData,
      },
      {
        label: renderTitle(i18n('entities.objective.label'), 'objective'),
        resourceUri: 'objective',
        options: results.objectiveData,
      },
      {
        label: renderTitle(i18n('entities.initiative.label'), 'initiative'),
        resourceUri: 'initiative',
        options: results.initiativeData,
      },
      {
        label: renderTitle(i18n('entities.interaction.label'), 'interaction'),
        resourceUri: 'interaction',
        options: results.interactionData,
      },
      {
        label: renderTitle(i18n('entities.fund.label'), 'fund'),
        resourceUri: 'fund',
        options: results.fundData,
      },
      {
        label: renderTitle(i18n('entities.research.label'), 'research'),
        resourceUri: 'research',
        options: results.researchData,
      },
      {
        label: renderTitle(i18n('entities.task.label'), 'task'),
        resourceUri: 'task',
        options: results.taskData,
      },
    ];
    return optionsToReturn;
  }

  const fetchAllResults = async () => {

    try {
      const results = await fetchFn(null, null);
      const processedResults = processResults(results);
      setFullDataSource(processedResults);
      return processedResults;
    } catch (error) {
      console.error(error);
      setFullDataSource([]);
      return [];
    }
  };

  const doSearch = (searchTerm, event) => {
    dispatch(actions.doFetch(searchTerm));
  };

  const groupOptions = () => {
    let _groupOptions = dataSource.map((optionGroup) => {
      return { label: optionGroup.label, options:
          optionGroup['options'].filter((item) => Boolean(item.id))
            .map((d) => (
              { value: optionGroup.resourceUri + '.' + d.id, label: d.label})
            )};
    });
    return _groupOptions;
  }

  const options = groupOptions();

  const onSelect = (value: string) => {
    const linkParts = value.split('.');
    window.location.assign(`/${linkParts[0]}/${linkParts[1]}`);
  };

 const getWindowDimensions = () => {
    const {
       innerWidth: width,
       innerHeight: height
    } = window;
    return {
       width,
       height
    };
 }

 const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
       function handleResize() {
          setWindowDimensions(getWindowDimensions());
       }

       window.addEventListener('resize', handleResize);
       return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
 }

  const { height } = useWindowDimensions();

  return (
    <>
      <StyledAutoComplete
        options={options}
        onSearch={handleSearch}
        onSelect={onSelect}
        listHeight={height-100}
        style={{ width: '30vw' }}
      >
        <Input.Search
          style={{ width: '30vw' }}
          id={'globalSearch'}
          name={'globalSearch'}
          placeholder={'Search...'}
          autoFocus={false}
          onSearch={doSearch}
          enterButton>
        </Input.Search>
      </StyledAutoComplete>
    </>
  );
};

export default GlobalSearch;
