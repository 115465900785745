import InteractionService from 'src/modules/interaction/interactionService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import CompanyService from 'src/modules/company/companyService';
import ObjectiveService from 'src/modules/objective/objectiveService';
import issuesListActions from 'src/modules/issues/list/issuesListActions';
import dayjs from 'dayjs';;

const prefix = 'INTERACTION_FORM';

const interactionFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  DUPLICATE_CHECK_STARTED: `${prefix}_DUPLICATE_CHECK_STARTED`,
  DUPLICATE_CHECK_SUCCESS: `${prefix}_DUPLICATE_CHECK_SUCCESS`,
  DUPLICATE_CHECK_ERROR: `${prefix}_DUPLICATE_CHECK_ERROR`,

  doInit: (id, company?, objective?) => async (dispatch) => {
    try {
      dispatch({
        type: interactionFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await InteractionService.find(id);
      } else {
        if (company)
          record['companies'] = [ await CompanyService.find(company) ];
        if (objective)
          record['objectiveLink'] = await ObjectiveService.find(objective);
      }

      dispatch({
        type: interactionFormActions.INIT_SUCCESS,
        payload: record,
      });

      dispatch(issuesListActions.doFetchAll());

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionFormActions.INIT_ERROR,
      });

      getHistory().push('/interaction');
    }
  },

  doCreate: (values, stayOnPage=false) => async (dispatch) => {
    try {
      dispatch({
        type: interactionFormActions.CREATE_STARTED,
      });

      const response = await InteractionService.create(values);

      dispatch({
        type: interactionFormActions.CREATE_SUCCESS,
        payload: response
      });

      Message.success(
        i18n('entities.interaction.create.success'),
      );

      if (!stayOnPage)
        getHistory().push('/interaction');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values, stayOnPage=false) => async (dispatch, getState) => {
    try {
      dispatch({
        type: interactionFormActions.UPDATE_STARTED,
      });
      await InteractionService.update(id, values);

      dispatch({
        type: interactionFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.interaction.update.success'),
      );

      if (!stayOnPage)
        getHistory().push(`/interaction/${id}`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionFormActions.UPDATE_ERROR,
      });
    }
  },

  doExistingRecordCheck: (companies, interactionDate) => async (dispatch, getState) => {
    try {
      dispatch({
        type: interactionFormActions.DUPLICATE_CHECK_STARTED,
      });

      const companyIds = (companies && companies.length) ? 
          companies.map((company) => company.id) : [];
      if (companyIds.length > 0) {
        const dateOnly = dayjs(interactionDate).format('YYYY-MM-DD');
        const filter = { company: companyIds, interactionDateRange: [dateOnly, dateOnly]};
        const results = await InteractionService.list(filter, null, 1, null);
  
        dispatch({
          type: interactionFormActions.DUPLICATE_CHECK_SUCCESS,
          payload: results,
        });
      } else {
        dispatch({
          type: interactionFormActions.DUPLICATE_CHECK_SUCCESS,
          payload: { rows: [], count: 0},
        });
      }
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: interactionFormActions.DUPLICATE_CHECK_ERROR,
      });
    }
  },
};

export default interactionFormActions;
