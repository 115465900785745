// const REACT_APP_BACKEND_URL: string = window.REACT_APP_BACKEND_URL || '/api';
// const REACT_APP_API_DOCUMENTATION_URL: string = window.REACT_APP_API_DOCUMENTATION_URL || '';
// const REACT_APP_FRONTEND_URL_HOST: string = window.REACT_APP_FRONTEND_URL_HOST || '/';
// const REACT_APP_FRONTEND_URL_PROTOCOL: string = window.REACT_APP_FRONTEND_URL_PROTOCOL || 'http';
// const REACT_APP_TENANT_MODE: string = window.REACT_APP_TENANT_MODE || 'single';
// const REACT_APP_FIRM_TENANT_ENABLED: boolean = window.REACT_APP_FIRM_TENANT_ENABLED || false;
// const REACT_APP_IS_PLAN_ENABLED: boolean = window.REACT_APP_IS_PLAN_ENABLED || false;
// const REACT_APP_IS_SIGNUP_ENABLED: boolean = window.REACT_APP_IS_SIGNUP_ENABLED || true;
// const REACT_APP_IS_ALTERNATE_SIGNUP: boolean = window.REACT_APP_IS_ALTERNATE_SIGNUP_ENABLED || false;
// const REACT_APP_IS_COMPLIANCE_ENABLED: boolean = window.REACT_APP_IS_COMPLIANCE_ENABLED || false;
// const REACT_APP_OBJECTIVE_DASHBOARD_URL: string = window.REACT_APP_OBJECTIVE_DASHBOARD_URL || '';
// const REACT_APP_OBJECTIVE_DASHBOARD_IFRAME_URL: string = window.REACT_APP_OBJECTIVE_DASHBOARD_IFRAME_URL || '';
// const REACT_APP_INTERACTION_DASHBOARD_URL: string = window.REACT_APP_INTERACTION_DASHBOARD_URL || '';
// const REACT_APP_INTERACTION_DASHBOARD_IFRAME_URL: string = window.REACT_APP_INTERACTION_DASHBOARD_IFRAME_URL || '';
// const REACT_APP_NOTIFICATION_POLL_INTERVAL: number = window.REACT_APP_NOTIFICATION_POLL_INTERVAL || 5000;

const backendUrl = window.REACT_APP_BACKEND_URL ? `${window.REACT_APP_BACKEND_URL}` : `/api`;
const apiDocumentationUrl = window.REACT_APP_API_DOCUMENTATION_URL;
const frontendUrl = {
  host: window.REACT_APP_FRONTEND_URL_HOST || 'localhost:3000',
  protocol: window.REACT_APP_FRONTEND_URL_PROTOCOL || 'http',
};
const tenantMode = window.REACT_APP_TENANT_MODE || 'multi';
const firmTenantEnabled = (window.REACT_APP_FIRM_TENANT_ENABLED) ? true : false;
const isPlanEnabled = (window.REACT_APP_IS_PLAN_ENABLED) ? true : false;
const stripePublishableKey = '';
const isSignupEnabled = (window.REACT_APP_IS_SIGNUP_ENABLED) ? true : false;
const alternateSignup = (window.REACT_APP_IS_ALTERNATE_SIGNUP_ENABLED) ? true : false;
const complianceEnabled = (window.REACT_APP_IS_COMPLIANCE_ENABLED) ? true : false;
const objectiveDashboardUrl = window.REACT_APP_OBJECTIVE_DASHBOARD_URL || '';
const objectiveDashboardIframeUrl = window.REACT_APP_OBJECTIVE_DASHBOARD_IFRAME_URL || '';
const interactionDashboardUrl = window.REACT_APP_INTERACTION_DASHBOARD_URL || '';
const interactionDashboardIframeUrl = window.REACT_APP_INTERACTION_DASHBOARD_IFRAME_URL || '';
const notificationPollInterval = window.REACT_APP_NOTIFICATION_POLL_INTERVAL || 5000;
const isMockMode = (window.REACT_APP_MOCK_MODE) ? true : false;

export default {
   frontendUrl,
   backendUrl,
   apiDocumentationUrl,
   tenantMode,
   isPlanEnabled,
   stripePublishableKey,
   isSignupEnabled,
   alternateSignup,
   complianceEnabled,
   objectiveDashboardUrl,
   objectiveDashboardIframeUrl,
   interactionDashboardUrl,
   interactionDashboardIframeUrl,
   notificationPollInterval,
   firmTenantEnabled,
   isMockMode
};

// export {
//     REACT_APP_BACKEND_URL,
//     REACT_APP_API_DOCUMENTATION_URL,
//     REACT_APP_FRONTEND_URL_HOST,
//     REACT_APP_FRONTEND_URL_PROTOCOL,
//     REACT_APP_TENANT_MODE,
//     REACT_APP_FIRM_TENANT_ENABLED,
//     REACT_APP_IS_PLAN_ENABLED,
//     REACT_APP_IS_SIGNUP_ENABLED,
//     REACT_APP_IS_ALTERNATE_SIGNUP,
//     REACT_APP_IS_COMPLIANCE_ENABLED,
//     REACT_APP_OBJECTIVE_DASHBOARD_URL,
//     REACT_APP_OBJECTIVE_DASHBOARD_IFRAME_URL,
//     REACT_APP_INTERACTION_DASHBOARD_URL,
//     REACT_APP_INTERACTION_DASHBOARD_IFRAME_URL,
//     REACT_APP_NOTIFICATION_POLL_INTERVAL
// };
