import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';
import { formatDate } from 'src/modules/shared/filter/filterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.benchmarkScore.fields.id'),
  },
  {
    name: 'date',
    label: i18n('entities.benchmarkScore.fields.date'),
    render: (value) => formatDate(value),
  },
  {
    name: 'scoreType',
    label: i18n('entities.benchmarkScore.fields.scoreType'),
    render: (value) =>
      i18n(
        `entities.benchmarkScore.enumerators.scoreType.${value}`,
      ),
  },
  {
    name: 'value',
    label: i18n('entities.benchmarkScore.fields.value'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'indicator',
    label: i18n('entities.benchmarkScore.fields.indicator'),
  },
  {
    name: 'benchmarkStudy',
    label: i18n('entities.benchmarkScore.importer.fields.studyIdentifier'),
    render: exporterRenders.relationToOne('identifier'),
  },
  {
    name: 'company',
    label: i18n('entities.benchmarkScore.importer.fields.companyISIN'),
    render: exporterRenders.relationToOne('isin'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.benchmarkScore.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.benchmarkScore.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
