import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import dayjs from 'dayjs';
import benchmarkScoreEnumerators from '../benchmarkScoreEnumerators';

export default [
  {
    name: 'date',
    label: i18n('entities.benchmarkScore.fields.date'),
    schema: schemas.date(
      i18n('entities.benchmarkScore.fields.date'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? dayjs(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'scoreType',
    label: i18n('entities.benchmarkScore.fields.scoreType'),
    schema: schemas.enumerator(
      i18n('entities.benchmarkScore.fields.scoreType'),
      {
        "options": benchmarkScoreEnumerators.scoreType
      },
    ),
  },
  {
    name: 'value',
    label: i18n('entities.benchmarkScore.fields.value'),
    schema: schemas.decimal(
      i18n('entities.benchmarkScore.fields.value'),
      {
        "required": true,
        "min": 0
      },
    ),
  },
  {
    name: 'indicator',
    label: i18n('entities.benchmarkScore.fields.indicator'),
    schema: schemas.string(
      i18n('entities.benchmarkScore.fields.indicator'),
      {
        "required": true,
        "min": 1,
        "max": 100
      },
    ),
  },
  {
    name: 'studyIdentifier',
    label: i18n('entities.benchmarkScore.importer.fields.studyIdentifier'),
    schema: schemas.string(
      i18n('entities.benchmarkScore.importer.fields.studyIdentifier'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'companyISIN',
    label: i18n('entities.benchmarkScore.importer.fields.companyISIN'),
    schema: schemas.relationToOne(
      i18n('entities.benchmarkScore.importer.fields.companyISIN'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'companyName',
    label: i18n('entities.benchmarkScore.importer.fields.companyName'),
    schema: schemas.relationToOne(
      i18n('entities.benchmarkScore.importer.fields.companyName'),
      {
        "required": true
      },
    ),
  },
];