import { Tooltip } from 'antd';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';
import authAxios from 'src/modules/shared/axios/authAxios';
import Avatar from 'src/view/shared/Avatar';

const UserAvatar = (props) => {
  const {
    value,
    label,
    user,
    displayRow,
    size,
    tooltip,
    noLabel,
    hideWhenEmpty,
    inList,
    withoutName
  } = props;

  const [avatar, setAvatar] = useState();
  const [fetchedUser, setFetchedUser] = useState(user);
  const [isLoading, setIsLoading] = useState(true);
  const [initials, setInitials] = useState<string>();
  const [userTooltipName, setUserTooltipName] = useState('');

  const getUser = async () => {
    try {
      if (!user && value) {
        const tenantId = AuthCurrentTenant.get();
        const { data } = await authAxios.get(`/tenant/${tenantId}/user/${value}`);

        setFetchedUser(data);

        const imageUrl = data?.avatars[0]?.downloadUrl;
        if (imageUrl) setAvatar(imageUrl);

      } else if (user) {
        const imageUrl = user?.avatars[0]?.downloadUrl;
        if (imageUrl) setAvatar(imageUrl);
      }
    } catch (e) {
      console.error('missing Avatar image file');
    }
  };

  const getName = (user, fullName = false) => {
    if (_.has(user, 'name')) {
      return _.get(user, 'name');
    }
    if (_.has(user, 'firstName')) {
      return `${_.get(user, 'firstName') ? _.get(user, 'firstName') : ''} 
        ${_.get(user, 'lastName')?.length > 0 ? (fullName ? _.get(user, 'lastName') : _.get(user, 'lastName')[0]) : ''}
      `;
    }
    if (_.has(user, 'fullName')) {
      return _.get(user, 'fullName');
    }
    if (_.has(user, 'label')) {
      return _.get(user, 'label');
    }
    const split = label?.split(' ');
    const firstName = _.first(split) ? split[0] : '';
    const initial = _.first(_.nth(split, 1)) ? split[1][0] : '';
    return `${firstName} ${initial?.toUpperCase()}`;
  }

  const renderAvatar = useCallback((withoutName) => (
    <div style={{
      display: 'flex',
      flexDirection: (displayRow ? 'row' : 'column'),
      justifyContent: 'center',
      ...(displayRow ? { alignItems: 'center', width: 'auto' } : (!inList ? { width: '70px' } : {}))
    }} className="user-avatar">
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <Avatar {...(avatar && avatar !== 'undefined' ? { avatarUrl: avatar } : { label: initials })}
          {...(size ? { size } : {})} />
      </div>
      {!withoutName ?
        <span style={{
          display: 'flex',
          justifyContent: 'center',
          marginLeft: '2px',
          textAlign: 'center',
        }}>
          {user ? getName(user) : getName(fetchedUser)}
        </span>
        : <></>
      }
    </div>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [displayRow, avatar, size, initials, user, fetchedUser]);

  useEffect(() => {
    const userToUse = user ? user : fetchedUser;
    const name = userToUse ? getName(user, true) : getName(fetchedUser, true);

    setUserTooltipName(name?.trim());
    const userInitials = (_.has(userToUse, 'firstName') ?
      `${_.get(userToUse, 'firstName')?.length > 0 ?
        _.get(userToUse, 'firstName')[0]?.substring(0, 1).toUpperCase()
        : ''}${_.get(userToUse, 'lastName')?.length > 0 ?
          _.get(userToUse, 'lastName')[0].substring(0, 1).toUpperCase()
          : ''}`
      : '');
    setInitials(userInitials);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fetchedUser]);

  useEffect(() => {
    if (avatar) {
      setIsLoading(false);
    } else {
      const userInitials = (_.has(user, 'firstName') ?
        `${_.get(user, 'firstName')?.length > 0 ? _.get(user, 'firstName')[0]?.substring(0, 1).toUpperCase() : ''}${_.get(user, 'lastName')?.length > 0 ? _.get(user, 'lastName')[0].substring(0, 1).toUpperCase() : ''}`
        : '');
      setInitials(userInitials);
    }
  }, [avatar, user]);

  useEffect(() => {
    setIsLoading(false);
  }, [initials]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return null;
  }

  if (hideWhenEmpty && !user && !fetchedUser) {
    return null;
  }

  return (
    <>
      {(tooltip && userTooltipName) ?
        <Tooltip title={userTooltipName}>
          {renderAvatar(true)}
        </Tooltip>
        :
        <>{renderAvatar((withoutName) ? withoutName : (noLabel || (tooltip && !userTooltipName)))}</>
      }
    </>
  );
};

export default UserAvatar;
