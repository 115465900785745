import authSelectors from 'src/modules/auth/authSelectors';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import CustomLoadable from 'src/view/shared/CustomLoadable';
import ProgressBar from 'src/view/shared/ProgressBar';
import routes from 'src/view/routes';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import config from 'src/config';
import { tenantSubdomain } from 'src/modules/tenant/tenantSubdomain';
import Layout from 'src/view/layout/Layout';

function RoutesComponent(props) {
  const isInitialMount = useRef(true);

  const authLoading = useSelector(
    authSelectors.selectLoadingInit,
  );
  const layoutLoading = useSelector(
    layoutSelectors.selectLoading,
  );
  const loading = authLoading || layoutLoading;
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      ProgressBar.start();
      return;
    }

    if (!loading) {
      ProgressBar.done();
    }
  }, [loading]);

  if (loading) {
    return <div />;
  }

  return (
    <Routes>
      {routes.publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={((props) => {
            const permissionChecker = new PermissionChecker(
              currentTenant,
              currentUser,
            );

            if (permissionChecker.isAuthenticated) {
              return <Navigate to="/" />;
            }

            const Component = CustomLoadable({
              loader: route.loader,
            });

            return <Component {...(props as unknown as object)} />;
          })()}
        />
        // <PublicRoute
        //   key={route.path}
        //   exact
        //   path={route.path}
        //   currentUser={currentUser}
        //   currentTenant={currentTenant}
        //   component={CustomLoadable({
        //     loader: route.loader,
        //   })}
        // />
      ))}

      {routes.emailUnverifiedRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={((props) => {
            const permissionChecker = new PermissionChecker(
              currentTenant,
              currentUser,
            );

            if (!permissionChecker.isAuthenticated) {
              return (
                <Navigate
                  to={{
                    pathname: '/auth/signin',
                  }}
                />
              );
            }

            if (permissionChecker.isEmailVerified) {
              return <Navigate to="/" />;
            }

            const Component = CustomLoadable({
              loader: route.loader,
            });

            return <Component {...(props as unknown as object)} />;
          })()}
        />
        // <EmailUnverifiedRoute
        //   key={route.path}
        //   exact
        //   path={route.path}
        //   currentUser={currentUser}
        //   currentTenant={currentTenant}
        //   component={CustomLoadable({
        //     loader: route.loader,
        //   })}
        // />
      ))}

      {routes.emptyTenantRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={((props) => {
            const permissionChecker = new PermissionChecker(
              currentTenant,
              currentUser,
            );

            if (!permissionChecker.isAuthenticated) {
              return (
                <Navigate
                  to={{
                    pathname: '/auth/signin',
                  }}
                />
              );
            }

            if (!permissionChecker.isEmptyTenant) {
              return <Navigate to="/" />;
            }

            const Component = CustomLoadable({
              loader: route.loader,
            });

            return <Component {...(props as unknown as object)} />;
          })()}
        />
        // <EmptyTenantRoute
        //   key={route.path}
        //   exact
        //   path={route.path}
        //   currentUser={currentUser}
        //   currentTenant={currentTenant}
        //   component={CustomLoadable({
        //     loader: route.loader,
        //   })}
        // />
      ))}

      {routes.emptyPermissionsRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={((props) => {
            const permissionChecker = new PermissionChecker(
              currentTenant,
              currentUser,
            );

            if (!permissionChecker.isAuthenticated) {
              return (
                <Navigate
                  to={{
                    pathname: '/auth/signin',
                  }}
                />
              );
            }

            if (!permissionChecker.isEmptyPermissions) {
              return <Navigate to="/" />;
            }

            const Component = CustomLoadable({
              loader: route.loader,
            });

            return <Component {...(props as unknown as object)} />;
          })()}
        />
        // <EmptyPermissionsRoute
        //   key={route.path}
        //   exact
        //   path={route.path}
        //   currentUser={currentUser}
        //   currentTenant={currentTenant}
        //   component={CustomLoadable({
        //     loader: route.loader,
        //   })}
        // />
      ))}

      {routes.privateRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={((props) => {
            const permissionChecker = new PermissionChecker(
              currentTenant,
              currentUser,
            );

            if (!permissionChecker.isAuthenticated) {
              return (
                <Navigate
                  to={{
                    // pathname: `/auth/signin?redirect=${location.pathname}`,
                    pathname: '/auth/signin',
                    // state: { from: location },
                  }}
                />
              );
            }

            if (!permissionChecker.isEmailVerified) {
              return <Navigate to="/auth/email-unverified" />;
            }

            if (
              ['multi', 'multi-with-subdomain'].includes(
                config.tenantMode,
              ) &&
              !tenantSubdomain.isSubdomain
            ) {
              if (permissionChecker.isEmptyTenant) {
                return <Navigate to="/auth/tenant" />;
              }
            } else {
              if (permissionChecker.isEmptyPermissions) {
                return (
                  <Navigate to="/auth/empty-permissions" />
                );
              }
            }

            if (!permissionChecker.match(route.permissionRequired)) {
              return <Navigate to="/403" />;
            }

            const Component = CustomLoadable({
              loader: route.loader,
            });

            return (
              <Layout {...(props as unknown as object)}>
                <Component {...(props as unknown as object)} />
              </Layout>
            );
          })()}
        />
        // <PrivateRoute
        //   key={route.path}
        //   currentUser={currentUser}
        //   currentTenant={currentTenant}
        //   permissionRequired={route.permissionRequired}
        //   path={route.path}
        //   component={CustomLoadable({
        //     loader: route.loader,
        //   })}
        //   exact={Boolean(route.exact)}
        // />
      ))}

      {routes.simpleRoutes.map((route) => {

        const CustomLoadableComponent = CustomLoadable({
          loader: route.loader,
        });

        return (<Route
            key={route.path}
            path={route.path}
            element={<CustomLoadableComponent />}
          />
        );
      })}
    </Routes>
  );
}

export default RoutesComponent;
