import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.initiative.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.initiative.fields.title'),
  },
  {
    name: 'team.teamName',
    label: i18n('entities.initiative.fields.team'),
  },
  {
    name: 'startDate',
    label: i18n('entities.initiative.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'target',
    label: i18n('entities.initiative.fields.target'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'stage',
    label: i18n('entities.initiative.fields.stage'),
  },
  {
    name: 'description',
    label: i18n('entities.initiative.fields.description'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.initiative.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.initiative.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
