const prefix = 'CUSTOM_REPORT';

const reportActions = {
  UPDATE_FILTER: `${prefix}_UPDATE_FILTER`,
  UPDATE_FILTER_ITEM: `${prefix}_UPDATE_FILTER_ITEM`,
  UPDATE_FILTER_ITEM_DEFAULT_VALUE: `${prefix}_UPDATE_FILTER_ITEM_DEFAULT_VALUE`,
  RESET_FILTER_ITEMS_TO_DEFAULT_VALUES: `${prefix}_RESET_FILTER_ITEMS_TO_DEFAULT_VALUES`,
  RESET_FILTER_DEFAULT_VALUES: `${prefix}_RESET_FILTER_DEFAULT_VALUES`,

  updateFilterItems: (filterName, values) => async (dispatch) => {
    dispatch({
      type: reportActions.UPDATE_FILTER,
      payload: {filterName, values}
    });
  },

  updateFilterItem: (filterName, name, values) => async (dispatch) => {
    dispatch({
      type: reportActions.UPDATE_FILTER_ITEM,
      payload: {filterName, name, values}
    });
  },

  updateFilterItemDefaultValue: (filterName, name, values) => async (dispatch) => {
    dispatch({
      type: reportActions.UPDATE_FILTER_ITEM_DEFAULT_VALUE,
      payload: {filterName, name, values}
    });
  },

  resetFilterItemsToDefaultValues: (filterName) => async (dispatch) => {
    await dispatch({
      type: reportActions.RESET_FILTER_ITEMS_TO_DEFAULT_VALUES,
      payload: {filterName}
    });
  },

  resetFilterDefaultValues: () => async (dispatch) => {
    await dispatch({
      type: reportActions.RESET_FILTER_DEFAULT_VALUES
    });
  },
};

export default reportActions;
