import SearchService from 'src/modules/search/searchService';
import selectors from 'src/modules/search/list/searchListSelectors';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'SEARCH_LIST';

const searchListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  SELECTEDS_CHANGED: `${prefix}_SELECTEDS_CHANGED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  doChangeSelected(payload) {
    return {
      type: searchListActions.SELECTEDS_CHANGED,
      payload,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: searchListActions.RESETED,
    });

    dispatch(searchListActions.doFetch());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const searchTerm = selectors.selectSearchTerm(getState());
    dispatch(searchListActions.doFetch(searchTerm, true));
  },
  
  doChangePaginationAndSort: (pagination, sorter) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: searchListActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch({
      type: searchListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(searchListActions.doFetchCurrentFilter());
  },

  doFetch: (searchTerm?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: searchListActions.FETCH_STARTED,
        payload: { searchTerm, keepPagination },
      });

      const response = await SearchService.list(
        searchTerm
      );

      dispatch({
        type: searchListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: searchListActions.FETCH_ERROR,
      });
    }

    getHistory().push('/search');
  },
};

export default searchListActions;
