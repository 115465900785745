import actions from 'src/modules/report/reportActions';

const initialData = {
  filters: {
    custom_report_filter: {},
    custom_report_content_filter: {},
  },
  default_values: {
    custom_report_filter: {},
    custom_report_content_filter: {}
  }
};

export default (state = initialData, { type, payload }) => {

  if (type === actions.UPDATE_FILTER_ITEM) {
    return {
      ...state,
      filters: {
        ...state.filters,
        [payload.filterName]: {
          ...state.filters[payload.filterName],
          [payload.name]: payload.values
        }
      }
    };
  } else if (type === actions.UPDATE_FILTER) {
    return {
      ...state,
      filters: {
        ...state.filters,
        [payload.filterName]: payload.values
      }
    };
  } else if (type === actions.UPDATE_FILTER_ITEM_DEFAULT_VALUE) {

    if (state.default_values[payload.filterName][payload.name] !== undefined) {
      return state;
    }

    return {
      ...state,
      default_values: {
        ...state.default_values,
        [payload.filterName]: {
          ...state.default_values[payload.filterName],
          [payload.name]: payload.values
        }
      }
    };
  } else if (type === actions.RESET_FILTER_ITEMS_TO_DEFAULT_VALUES) {
    return {
      ...state,
      filters: {...state.default_values}
    };
  } else if (type === actions.RESET_FILTER_DEFAULT_VALUES) {
    return {
      ...state,
      filters: {
        custom_report_filter: {},
        custom_report_content_filter: {}
      },
      default_values: {
        custom_report_filter: {},
        custom_report_content_filter: {}
      }
    };
  }

  return state;
};
