import Errors from 'src/modules/shared/error/errors';
import NotificationService from '../notificationService';
import authService from 'src/modules/auth/authService';

const prefix = 'NOTIFICATION';

const notificationListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  NOTIFICATION_READ: `${prefix}_READ`,
  NOTIFICATION_UNREAD: `${prefix}_UNREAD`,
  NOTIFICATION_DELETE: `${prefix}_DELETE`,

  doFetch: (notificationTerm?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: notificationListActions.FETCH_STARTED,
        payload: { notificationTerm, keepPagination },
      });

      const response = await NotificationService.list();

      dispatch({
        type: notificationListActions.FETCH_SUCCESS,
        payload: {
          rows: response,
          count: response.length,
        },
      });
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        authService.signout();
        window.location.reload();
        return;
      }
    }
  },

  doRead: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: notificationListActions.NOTIFICATION_READ,
        payload: { _id: id },
      });

      NotificationService.doRead(id);

    } catch (error) {
      Errors.showMessage(error);
    }
  },

  doUnRead: (id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: notificationListActions.NOTIFICATION_UNREAD,
        payload: { _id: id },
      });

      NotificationService.doUnread(id);
    } catch (error) {
      Errors.showMessage(error);
    }
  },

  doDelete: (id) => async (dispatch, getState) => {
    try {
      await NotificationService.destroyAll([id]);
      dispatch({
        type: notificationListActions.NOTIFICATION_DELETE,
        payload: { _id: id },
      });

    } catch (error) {
      Errors.showMessage(error);
    }
  },
};

export default notificationListActions;
