import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'fundName',
    label: i18n('entities.fund.fields.fundName'),
    schema: schemas.string(
      i18n('entities.fund.fields.fundName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'fundObjective',
    label: i18n('entities.fund.fields.fundObjective'),
    schema: schemas.string(
      i18n('entities.fund.fields.fundObjective'),
      {},
    ),
  },
  {
    name: 'identifier',
    label: i18n('entities.fund.fields.identifier'),
    schema: schemas.string(
      i18n('entities.fund.fields.identifier'),
      {},
    ),
  },
];