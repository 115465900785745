import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.company.fields.id'),
  },
  {
    name: 'companyName',
    label: i18n('entities.company.fields.companyName'),
  },
  {
    name: 'ticker',
    label: i18n('entities.company.fields.ticker'),
  },
  {
    name: 'address',
    label: i18n('entities.company.fields.address'),
  },
  {
    name: 'country',
    label: i18n('entities.company.fields.country'),
  },
  {
    name: 'region',
    label: i18n('entities.company.fields.region'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'marketCap',
    label: i18n('entities.company.fields.marketCap'),
  },
  {
    name: 'sector',
    label: i18n('entities.company.fields.sector'),
  },
  {
    name: 'industry',
    label: i18n('entities.company.fields.industry'),
  },
  {
    name: 'sic',
    label: i18n('entities.company.fields.sic'),
  },
  {
    name: 'isin',
    label: i18n('entities.company.fields.isin'),
  },
  {
    name: 'sedol',
    label: i18n('entities.company.fields.sedol'),
  },
  {
    name: 'cusip',
    label: i18n('entities.company.fields.cusip'),
  },
  {
    name: 'bloomberg',
    label: i18n('entities.company.fields.bloomberg'),
  },
  {
    name: 'lei',
    label: i18n('entities.company.fields.lei'),
  },
  {
    name: 'logo',
    label: i18n('entities.company.fields.logo'),
  },
  {
    name: 'classifier',
    label: i18n('entities.company.fields.classifier'),
  },
  {
    name: 'tags',
    label: i18n('entities.company.fields.tags'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'status',
    label: i18n('entities.company.fields.status'),
  },
  {
    name: 'gicSector',
    label: i18n('entities.company.fields.gicSector'),
  },
  {
    name: 'gicGroup',
    label: i18n('entities.company.fields.gicGroup'),
  },
  {
    name: 'gicIndustry',
    label: i18n('entities.company.fields.gicIndustry'),
  },
  {
    name: 'gicSubIndustry',
    label: i18n('entities.company.fields.gicSubIndustry'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.company.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.company.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
