import list from 'src/modules/interaction/list/interactionListReducers';
import form from 'src/modules/interaction/form/interactionFormReducers';
import view from 'src/modules/interaction/view/interactionViewReducers';
import destroy from 'src/modules/interaction/destroy/interactionDestroyReducers';
import importerReducer from 'src/modules/interaction/importer/interactionImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
