import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'entity',
    label: i18n('entities.following.fields.entity'),
    schema: schemas.string(
      i18n('entities.following.fields.entity'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'identity',
    label: i18n('entities.following.fields.identity'),
    schema: schemas.string(
      i18n('entities.following.fields.identity'),
      {},
    ),
  },
  {
    name: 'filter',
    label: i18n('entities.following.fields.filter'),
    schema: schemas.string(
      i18n('entities.following.fields.filter'),
      {},
    ),
  },
];