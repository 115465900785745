const interactionEnumerators = {
  meetingType: [
    'phone',
    'email',
    'site',
    'webinar',
    'conference',
    'conference_call',
    'meeting_group',
    'meeting_one',
    'voting',
  ],
  purpose: [
    'deal',
    'roadshow',
    'corporate',
    'update',
    'investment',
    'engagement',
  ],
  arrangedBy: [
    'broker',
    'house_broker',
    'issuer',
    'PM'
  ],
  investmentAction: [
    'no_action',
    'monitor',
    'invest',
    'increase',
    'decrease',
    'divest'
  ],
  direction: [
    'incoming',
    'outgoing',
  ],
};

export default interactionEnumerators;
