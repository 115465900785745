const tagEnumerators = {
  category: [
    'environment',
    'social',
    'governance',
    'strategy',
    'internal',
    'other',
  ],
};

export default tagEnumerators;
