import {
  AppstoreOutlined,
  CodeOutlined,
  LockOutlined,
  LogoutOutlined,
  SettingOutlined,
  UserOutlined
} from '@ant-design/icons';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Dropdown, Layout, Menu, Modal, Tooltip } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import config from 'src/config';
import { i18n } from 'src/i18n';
import authActions from 'src/modules/auth/authActions';
import authSelectors from 'src/modules/auth/authSelectors';
import { useAppDispatch } from 'src/modules/hooks';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import { getHistory } from 'src/modules/store';
import CreateNew from 'src/view/layout/CreateNew';
import GlobalSearch from 'src/view/layout/GlobalSearch';
import HeaderWrapper from 'src/view/layout/styles/HeaderWrapper';
import Bell from 'src/view/notification/Bell';
import Notifications from 'src/view/notification/Notifications';
import { bgMediumLight, borderLight, textLight, textMediumLight } from 'src/view/shared/Colours';
import UserAvatar from 'src/view/shared/UserAvatar';
import WelcomeTour from 'src/view/shared/WelcomeTour';
import styled from 'styled-components';

const BackButtonWrapper = styled.div`
  display: flex;
  color: ${textMediumLight};
  :hover {
    cursor: pointer;
    color: ${textLight};
  }
`;

const BackButton = styled(Button)`
  top: -3px;
  color: ${textMediumLight};
  height: 2.4em;
  width: 2.4em;
  border-radius: 50%;
  border-color: ${borderLight};
  background-color: ${bgMediumLight};
`;

const { Header: AntHeader } = Layout;

const Header = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );
  const userDropdownText = useSelector(
    authSelectors.selectCurrentUserNameOrEmailPrefix,
  );

  const menuItems = [
    { label: i18n('auth.profile.title'), key: '/profile', icon: <UserOutlined /> },
    { label: i18n('settings.menu'), key: '/settings', icon: <SettingOutlined /> },
    { label: i18n('auth.passwordChange.title'), key: '/password-change', icon: <LockOutlined /> },
    ...(
      ['multi', 'multi-with-subdomain'].includes(config.tenantMode,) ?
        [{ label: i18n('auth.tenants'), key: '/tenant', icon: <AppstoreOutlined /> }]
        : []
    ),
    ...(
      config.apiDocumentationUrl ? [{
        label:
          <a
            href={config.apiDocumentationUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            onClick={e => e.stopPropagation()}
          >
            {i18n('api.menu')}
          </a>
        , key: config.apiDocumentationUrl, icon: <CodeOutlined style={{ marginRight: 8 }} />
      }] : []
    ),
    { type: 'divider' },
    {
      label: <a href="/" onClick={(e) => {
        e.stopPropagation(); dispatch(authActions.doSignout())
      }}>
        {i18n('auth.signout')}
      </a>, key: 'logout', icon: <LogoutOutlined />
    }
  ];

  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );

  const userMenu = (
    <Menu selectedKeys={[]}
      onClick={({ key }) => navigate(`${key}`)}
      items={menuItems as ItemType[]}
    />
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(false);
  const [isNoticeModalOpen, setIsNoticeModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showNoticeModal = () => {
    setIsNoticeModalOpen(true);
  };

  const handleOk = () => {
    const acceptResult = dispatch(authActions.doAcceptTermsOfService(currentUser.id));
    console.log(acceptResult);
    setIsModalOpen(false);
    setShowWelcomeModal(true);
  };
  const handleNoticeOk = () => {
    setIsNoticeModalOpen(false);
    sessionStorage.setItem('noticeAccepted', 'true');
  };

  // useEffect function that runs once on page load
  useEffect(() => {
    if (!currentUser?.termsOfServiceAccepted && currentTenant.plan === 'free') {
      showModal();
    }
    if (currentUser?.isExternal && sessionStorage.getItem('noticeAccepted') !== 'true') {
      showNoticeModal();
    }
  }, []);

  return (
    <>
      <HeaderWrapper style={{
        marginBottom: '2em',
        position: 'fixed',
        width: menuVisible ? 'calc(100% - 14.5em)' : '100%',
        transform: menuVisible ? 'translateX(14.5em)' : 'translateX(0)',
        transition: 'all 300ms ease-in-out',
        zIndex: '2',
      }}>
        <AntHeader>
          <BackButtonWrapper
            onClick={getHistory().goBack}
          >
            <BackButton
              type="primary"
              icon={<FontAwesomeIcon icon={faArrowLeftLong} />}
              htmlType="button"
            />
            <div style={{
              paddingTop: '0.4em',
              paddingLeft: '0.5em',
              fontWeight: 'bold',
            }}> Back </div>
          </BackButtonWrapper>
          <GlobalSearch {...props} />
          <div style={{ display: 'flex', position: 'relative', top: '-2px' }}>
            <CreateNew {...props} />
            <Bell setModalOpen={setNotificationsOpen} />
            <Divider
              type="vertical"
              style={{
                height: '100%',
                marginLeft: '1.5em',
                marginRight: '1.5em',
                borderLeft: '1px solid rgba(0,0,0,0.12)'
              }}
            />
            <Dropdown
              arrow={true}
              className="user-dropdown"
              overlay={userMenu}
              trigger={['click']}
            >
              <div className="user-dropdown-content">
                <span className="user-dropdown-text">
                  <span style={{ fontWeight: 'bold' }}>{userDropdownText}</span>{' '}
                  {['multi', 'multi-with-subdomain'].includes(
                    config.tenantMode,
                  ) && (
                      <span className="user-dropdown-text-tenant">
                        {currentTenant && currentTenant.name}
                      </span>
                    )}
                </span>
                <UserAvatar user={currentUser} noLabel={true} />
              </div>
            </Dropdown>
          </div>
        </AntHeader>
      </HeaderWrapper>
      <Notifications isOpen={notificationsOpen} setOpen={setNotificationsOpen} />
      <Modal
        style={{ top: 24 }}
        title='Trial Service Agreement'
        centered
        open={isModalOpen}
        onOk={handleOk}
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            Accept
          </Button>,
        ]}
      >
        <p>Please review and accept the Trial Service Agreement before you start to use the Impactive platform. If you have
          any questions please contact info@impactive.pro
        </p>
        <a href='/Trial-Service-Agreement.doc' target='_blank' rel='noopener noreferrer'>Trial Service Agreement</a>
      </Modal>
      <Modal
        style={{ top: 24 }}
        title='Important Notice'
        centered
        open={isNoticeModalOpen}
        footer={[
          <Button key="submit" type="primary" onClick={handleNoticeOk}>
            Accept
          </Button>,
        ]}
      >
        <p>The engagement data within this platform is confidential and not be shared outside of your organisation.
        </p>
      </Modal>

      {/* <WelcomeTour /> */}
    </>
  );
};

export default Header;
